import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Button, Chip, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { AuthContext } from '../../context/AuthContextProvider';
import { getHorarios, updateHorarios } from '../../api/horariosApi';
import { checkHorario, weekDays } from '../../utils/dateUtils';
import Loader from '../../components/common/Loader';
import NewHorarioDialog from '../../components/horarios/NewHorarioDialog';
import ErrorHorarioDialog from '../../components/horarios/ErrorHorarioDialog';
import { toast } from 'react-toastify';
import AddRoundedIcon from '@material-ui/icons/AddRounded'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '100px',
    marginLeft: '20px',
    marginRight: '20px'
  },
  title: {
    marginBottom: '30px',
  },
  title2: {
    fontSize: '20px',
    fontWeight: '700',
    marginBottom: '10px'
  },
  paper: {
    padding: '25px'
  },
  chip: {
    marginRight: '5px',
    marginBottom: '10px'
  },
  buttonGuardar: {
    marginRight: '20px',
    maxHeight: '30px',
    marginTop: '10px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
}));

const Horarios = () => {

  const { negocioActivo } = useContext(AuthContext);
  const classes = useStyles();

  const [horarios, setHorarios] = useState({});
  const [loading, setLoading] = useState(true)
  const [lastId, setLastId] = useState(-1);
  const [nuevoHorario, setNuevoHorario] = useState(false);
  const [diaSelected, setDiaSelected] = useState(0);
  const [error, setError] = useState(null);
  const [cambios, setCambios] = useState(false);

  useEffect(() => {
    if (negocioActivo) {
      fetchHorarios();
    }
  }, [negocioActivo]) // eslint-disable-line

  const fetchHorarios = () => {
    setLoading(true);
    let id = 1;
    getHorarios(negocioActivo.id).then(res => {
      let horarios = {};
      weekDays.forEach((wd) => {
        horarios[wd.number] = [];
      });
      res.data.map(h => {
        let newHorario = { id, abre: h.abre, cierra: h.cierra, dia: h.dia };
        id++;
        horarios[h.dia] = [
          ...horarios[h.dia],
          newHorario
        ];
        return -1;
      });
      setLastId(id);
      setHorarios(horarios);
    }).finally(() => setLoading(false))
  }

  const onDeleteHorario = (dia, id) => {
    setCambios(true);
    setHorarios({
      ...horarios,
      [dia]: horarios[dia].filter(h => h.id !== id)
    })
  }

  const onAgregarHorario = (dia, abre, cierra) => {
    let horariosAnteriores = horarios[dia] ? horarios[dia] : [];
    let check = checkHorario(abre, cierra, horariosAnteriores);
    if (check.result) {
      setCambios(true);
      setHorarios({
        ...horarios,
        [dia]: [
          ...horariosAnteriores,
          { abre, cierra, lastId, dia }
        ]
      });
      setLastId(lastId + 1);
    } else {
      setError(check.error);
    }
  }

  const onGuardar = () => {
    setLoading(true);
    let body = [];
    weekDays.map((wd) => {
      body = [
        ...body,
        ...horarios[wd.number]
      ];
      return -1;
    });
    updateHorarios(body, negocioActivo.id).then((res) => {
      setCambios(false);
      toast.success("Horarios actualizados");
    }).catch((err) => {
      console.log(err);
      toast.error("Error al actualizar horarios");
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <div className={classes.root}>
      <Grid container justify='space-between'>
        <Typography component="h1" variant="h5" className={classes.title}>
          Horarios
        </Typography>
        <Button
          variant='contained'
          color='primary'
          className={classes.buttonGuardar}
          disabled={!cambios}
          onClick={onGuardar}
        >
          Guardar cambios
        </Button>
      </Grid>
      <Grid container spacing={5}>
        {
          weekDays.map(wd => <Grid item xs={12} sm={6} key={wd.number + wd.name}>
            <Paper className={classes.paper}>
              <div className={classes.title2}>
                {wd.name}
              </div>
              <Grid container direction='row'>
                {
                  horarios[wd.number] && horarios[wd.number].map(h => <Grid item md={4} sm={6} xs={12} key={h.id}>
                    <Chip
                      className={classes.chip}
                      label={`Abre ${h.abre.substring(0, 5)} hs - Cierra ${h.cierra.substring(0, 5)} hs`}
                      onDelete={() => onDeleteHorario(wd.number, h.id)}
                    />
                  </Grid>
                  )
                }
              </Grid>
              <Button
                color='primary'
                onClick={() => {
                  setDiaSelected(wd.number);
                  setNuevoHorario(true);
                }}
                startIcon={<AddRoundedIcon />}
              >
                Agregar
                </Button>
            </Paper>
          </Grid>
          )
        }
        {
          nuevoHorario &&
          <NewHorarioDialog
            dia={diaSelected}
            handleClose={() => {
              setNuevoHorario(false);
              setDiaSelected(0);
            }}
            handleSubmit={onAgregarHorario}
          />
        }
        {
          error &&
          <ErrorHorarioDialog
            error={error}
            handleClose={() => setError(null)}
          />
        }
        {
          loading &&
          <Backdrop className={classes.backdrop} open={loading}>
            <Loader />
          </Backdrop>
        }
      </Grid>
    </div>
  )
}

export default Horarios;