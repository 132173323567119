import { Grid, makeStyles, Switch, Divider, Tooltip } from '@material-ui/core';
import React from 'react';
import ItemLayout from '../../common/ItemLayout';
import { isMobile } from 'react-device-detect';
import WarningIcon from '@material-ui/icons/Warning';
const useStyles = makeStyles((theme) => ({
  tipoProductoItemText: {
    paddingTop: '10px',
    paddingBottom: '10px',
    textTransform: 'uppercase',
    fontSize: '12px',
    marginLeft: '5px'
  },
  avatar: {
    width: '50px'
  },
  switch: {
    marginRight: isMobile ? '10px' : 'auto'
  },
  tooltip: {
    fontSize: '42px'
  }
}));

const ProductoItem = ({
  producto,
  onSelect,
  selected,
  setDisponible
}) => {
  const classes = useStyles();

  return (
    <ItemLayout selected={selected} onSelect={onSelect}>
      <Grid container direction='row' justify="center" alignItems="center">
        <Grid container direction="row" xs alignItems="center">
          <Grid item xs={6} md={2}>
            <img alt="Producto" className={classes.avatar} src={process.env.REACT_APP_BASE_URL_FASTER_API + '/' + producto.imagen} />
          </Grid>
          <Grid item xs={12} md={10}>
            <Grid container>
              <Grid item xs={7}>
                <span className={classes.tipoProductoItemText} >
                  {producto.titulo}
                </span>
              </Grid>
              {producto.pendiente && <Grid item xs={3}>
                <Tooltip title="Este producto se encuentra en revisión" style={{ fontSize: '18px'}}>
                <WarningIcon fontSize="small" style={{ color: "#ff9800", marginLeft: '1rem' }} />
                </Tooltip>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} className={classes.switch}>
          <Switch checked={producto.disponible} color="primary" className={classes.switch} onChange={(e) => setDisponible(producto.id, e.target.checked)} />
        </Grid>
      </Grid>
      {
        isMobile &&
        <Divider />
      }
    </ItemLayout>
  )
}

export default ProductoItem;