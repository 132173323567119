import React from 'react';
import { Button, Grid, Paper, makeStyles } from '@material-ui/core';
import CategoriaProductoItem from '../items/CategoriaProductoItem';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  columns: {
    minHeight: isMobile ? 'auto' : '100vh',
    marginBottom: '40px'
  },
}));

const CategoriasProductosColumn = ({
  categoriasProductos,
  categoriaSelected,
  setCrearNuevaCategoria,
  setOrdenarCategorias,
  onSelectCategoria
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.columns}>
      {
        categoriasProductos.map((categoria, key) =>
          <CategoriaProductoItem
            tipo={categoria.nombre}
            key={'categoria-' + key}
            onSelect={() => onSelectCategoria(categoria)}
            selected={categoriaSelected && (categoriaSelected.id === categoria.id)}
            cant={categoria.cant_productos}
          />
        )
      }
      <Grid container justify='space-evenly'>
        <Grid item sm={12} md={6}>
          <Button
            color='primary'
            onClick={() => setCrearNuevaCategoria(true)}
            startIcon={<AddRoundedIcon />}
          >
            Agregar categoría
          </Button>
        </Grid>
        <Grid item sm={12} md={6}>
          <Button
            color='secondary'
            onClick={() => setOrdenarCategorias(true)}
            startIcon={<FilterListRoundedIcon />}
          >
            Ordenar categorías
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CategoriasProductosColumn;