import { getToken } from "../utils/authUtils";
import Axios from "axios";

// export const BASEURL = "https://localhost:5001";
export const BASEURL = process.env.REACT_APP_BASE_URL_NEGOCIOS;
export const FASTERAPIURL = process.env.REACT_APP_BASE_URL_FASTER_API;

export async function post(endpoint, auth, body) {
    const headers = await getHeaders(auth);
    return Axios.post(BASEURL + endpoint, body, { headers });
}

export async function get(endpoint, auth) {
    const headers = await getHeaders(auth);
    return Axios.get(BASEURL + endpoint, { headers });
}

export async function update(endpoint, auth, body) {
    const headers = await getHeaders(auth);
    return Axios.put(BASEURL + endpoint, body, { headers });
}

export async function del(endpoint, auth) {
    const headers = await getHeaders(auth);
    return Axios.delete(BASEURL + endpoint, { headers });
}

export async function getHeaders(auth) {
    let headers = {
        "content-type": "application/json",
        "accept": "application/json",
    };
    if (auth) {
        const token = getToken();
        // const negocio_id = getNegocioId();
        headers = Object.assign({
            "Authorization": "Bearer " + token,
        }, headers)

    }
    return headers;
}
