import React from 'react';
import { Grid, Paper, Button, makeStyles } from '@material-ui/core';
import TablaCadetes from '../tablas/TablaCadetes';

const useStyles = makeStyles((theme) => ({
  title2: {
    fontSize: '20px',
    fontWeight: '700'
  },
  paper: {
    padding: '25px'
  },
  button: {
    marginTop: '15px'
  }
}));


const ReportesCadetesCards = ({
  cleanReportes,
  reportes,
  startDate,
  endDate
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container justify='space-between'>
            <div className={classes.title2}>
              {`Resumen: ${startDate.format('DD/MM/YYYY')} - ${endDate.format('DD/MM/YYYY')}`}
            </div>
            <p>*Se asume que el envío en efectivo es cobrado por adelantado por el cadete</p>

            <Button onClick={cleanReportes} variant='contained' color="primary" className={classes.button}>
              Limpiar reporte
            </Button>
          </Grid>
          <TablaCadetes cadetes={reportes} />
        </Paper>
      </Grid>
    </>
  )
}

export default ReportesCadetesCards;