import { Grid, makeStyles, Paper, Typography, Backdrop } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import Loader from '../../components/common/Loader';
import { getAllTiposIngredientes } from '../../api/negociosApi';
import FormTipoIngrediente from '../../components/tipos-ingredientes/forms/FormTipoIngrediente';
import {
  createTipoIngrediente,
  deleteTipoIngrediente,
  updateTipoIngrediente,
} from '../../api/tiposIngredientesApi';
import { AuthContext } from '../../context/AuthContextProvider';
import TiposIngredientesColumn from '../../components/tipos-ingredientes/columns/TiposIngredientesColumn';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '100px',
    marginLeft: '20px',
    marginRight: '20px',
  },
  title: {
    marginBottom: '30px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const TiposIngredientes = () => {
  const { negocioActivo } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [tipoSelected, setTipoSelected] = useState(null);
  const [tiposIngredientes, setTiposIngredientes] = useState([]);
  const [crearNuevoTipoGlobal, setCrearNuevoTipoGlobal] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getAllTiposIngredientes(negocioActivo.id)
      .then((response) => {
        setTiposIngredientes(response.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const onSubmitTipoIngrediente = (tipoIngrediente) => {
    setLoading(true);
    let body = {
      ...tipoIngrediente,
      ingredientes: tipoIngrediente.ingredientes.map((ing) => {
        return { ...ing, id: 0 };
      }),
    };
    crearNuevoTipoGlobal
      ? createTipoIngrediente(body)
          .then((res) => {
            setTipoSelected(null);
            fetchData();
          })
          .catch((e) => console.log(e))
      : updateTipoIngrediente(body, body.id)
          .then((res) => {
            setTipoSelected(null);
            fetchData();
          })
          .catch((e) => console.log(e));
  };

  const onEliminarTipoIngrediente = (tipoIngrediente) => {
    setLoading(true);
    deleteTipoIngrediente(tipoIngrediente.id).then(() => {
      setTipoSelected(null);
      fetchData();
    });
  };

  const renderTipoSelectedColumn = () => {
    return (
      <Paper className={classes.columns}>
        {(tipoSelected || crearNuevoTipoGlobal) && (
          <FormTipoIngrediente
            negocioId={negocioActivo.id}
            onSubmit={onSubmitTipoIngrediente}
            tipoIngrediente={tipoSelected}
            onCancelar={() => {
              setTipoSelected(null);
              setCrearNuevoTipoGlobal(false);
            }}
            onEliminar={onEliminarTipoIngrediente}
          />
        )}
      </Paper>
    );
  };

  const onSelectTipoIngrediente = (tipo) => {
    setCrearNuevoTipoGlobal(false);
    setTipoSelected(tipo);
  };

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h5" className={classes.title}>
        Modificar Tipos de Ingredientes
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <TiposIngredientesColumn
            onAgregarTipoIngredienteGlobal={() => {
              setTipoSelected(null);
              setCrearNuevoTipoGlobal(true);
            }}
            onSelectTipoIngrediente={onSelectTipoIngrediente}
            tipoSelected={tipoSelected}
            tiposIngredientes={tiposIngredientes}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          {renderTipoSelectedColumn()}
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>
    </div>
  );
};

export default TiposIngredientes;
