import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'react-device-detect';

const FormHeader = ({
  tipoIngrediente,
  onCancelar
}) => {

  return (
    <Grid justify='space-between' direction='row' container >
      <Grid item xs={10} sm={5}>
        <Typography component="h1" variant="h5">
          {
            tipoIngrediente ?
              'Modificar datos del tipo' :
              'Crear nuevo tipo'
          }
        </Typography>
      </Grid>
      {
        ((!tipoIngrediente || tipoIngrediente.global) && !isMobile) &&
        <Grid item xs={5}>
          <Typography component="h1" variant="h5">
            {
              tipoIngrediente ?
                'Modificar ingredientes del tipo' :
                'Agregar ingredientes al tipo'
            }
          </Typography>
        </Grid>
      }
      <Grid item xs={2}>
        <IconButton onClick={onCancelar} aria-label="delete">
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default FormHeader;