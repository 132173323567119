import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const ConfirmarDialog = ({
  handleClose,
  handleConfirm,
  message
}) => {
  return <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">¿Estás seguro?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Cancelar
      </Button>
      <Button onClick={handleConfirm} variant='contained' color="secondary">
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
}

export default ConfirmarDialog;