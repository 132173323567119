import React from 'react';
import { Avatar, Button, Dialog, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main
  },
  item: {
    cursor: 'grab',
  },
  buttonsContainer: {
    marginTop: '15px',
    justifyContent: 'space-evenly'
  }
}))

const SortableCategoriaItem = SortableElement(({ categoria, num }) => {
  const classes = useStyles();
  console.log(num);
  return <ListItem className={classes.item}>
    <ListItemAvatar>
      <Avatar className={classes.avatar}>{num + 1}</Avatar>
    </ListItemAvatar>
    <ListItemText primary={categoria.nombre} />
  </ListItem>
});

const SortableCategoriaList = SortableContainer(({ categorias }) => {
  return (
    <List dense>
      {categorias.map((categoria, index) => (
        <SortableCategoriaItem key={`categoria-${index}`} index={index} num={index} categoria={categoria} />
      ))}
    </List>
  );
});

const OrdenCategoriasProductosDialog = ({
  handleClose,
  onSubmit,
  categorias,
}) => {
  const classes = useStyles();
  const [categoriasOrdenadas, setCategoriasOrdenadas] = React.useState([...categorias]);

  const handleSubmit = () => {
    const categoriasIds = categoriasOrdenadas.map(cat => cat.id);
    onSubmit(categoriasIds);
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setCategoriasOrdenadas(arrayMove([...categoriasOrdenadas], oldIndex, newIndex));
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={true}>
      <DialogTitle id="simple-dialog-title">Ordenar categorías de productos</DialogTitle>
      <SortableCategoriaList categorias={categoriasOrdenadas} onSortEnd={onSortEnd} />
      <Grid container  className={classes.buttonsContainer}>
        <Grid item xs={4}>
          <Button
            variant='contained'
            color='secondary'
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>


    </Dialog>
  )
}

export default OrdenCategoriasProductosDialog;