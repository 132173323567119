import React from 'react';
import { Paper, Typography, makeStyles, Button, Divider } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import TipoIngredienteItem from '../items/TipoProductoItem';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  subittle: {
    marginBottom: '10px',
    marginLeft: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '20px'
  },
  columns: {
    minHeight: isMobile ? 'auto' : '100vh',
    marginBottom: '40px'
  },
}));

const TiposIngredientesColumn = ({
  onAgregarTipoIngredienteGlobal,
  onSelectTipoIngrediente,
  tiposIngredientes,
  tipoSelected
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.columns}>
      <Typography component="span" variant="subtitle2" className={classes.subittle}>
        Tipos globales
      </Typography>
      {
        tiposIngredientes.filter(ti => ti.global).map((tipo, key) =>
          <TipoIngredienteItem
            tipo={tipo.nombre}
            key={'tipo-' + key}
            onSelect={() => onSelectTipoIngrediente(tipo)}
            selected={tipoSelected && (tipoSelected.id === tipo.id)}
          />
        )
      }
      <Button
        color='primary'
        onClick={onAgregarTipoIngredienteGlobal}
        startIcon={<AddRoundedIcon />}
      >
        Agregar tipo de ingrediente global
      </Button>
      <Divider />
      <Typography component="span" variant="subtitle2" className={classes.subittle}>
        Tipos propios de productos
      </Typography>
      {
        tiposIngredientes.filter(ti => !ti.global).map((tipo, key) =>
          <TipoIngredienteItem
            tipo={tipo.nombre}
            key={'tipo-' + key}
            onSelect={() => onSelectTipoIngrediente(tipo)}
            selected={tipoSelected && (tipoSelected.id === tipo.id)}
          />
        )
      }
    </Paper>
  )
}

export default TiposIngredientesColumn;