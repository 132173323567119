import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Grid, Typography } from "@material-ui/core";
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  nav: {
    backgroundColor: theme.palette.dark.secondary,
    color: theme.palette.primary.main,
    alignItems: 'center'
  },
  title: {
    flexGrow: 1,
    alignItems: 'center',
    fontSize: '30px',
    marginLeft: '10px',
    textAlign: 'center',
    fontFamily: 'Arial',
  },
  logo: {
    width: '40px',
    height: '40px'
  }
}));

const Navbar = () => {
  const classes = useStyles();

  return (
    <Grid item className={classes.root}>
      <AppBar position="fixed" className={classes.nav}>
        <Toolbar style={{ justifyContent: 'center' }}>
          <img alt="Logo Faster" src="http://api.fasterdelivery.com.ar/images/menu/logoFaster.png" className={classes.logo} />
          {
            !isMobile &&
            <Typography component='span' className={classes.title}>Negocios</Typography>
          }
        </Toolbar>
      </AppBar>
    </Grid>
  )
}

export default Navbar