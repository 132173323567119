import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const ErrorHorarioDialog = ({
  handleClose,
  error
}) => {

  return <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Se produjo un error</DialogTitle>
    <DialogContent>
      <DialogContentText>
        El horario indicado no pudo ser creado correctamente. Recuerde que el dia es de 00hs a 23:59hs.
        Ejemplo: si su negocio abre de 20 a 03 el dia martes, debe establecer el horario de 20 a 23:59 el martes, y de 00 a 03 el miercoles.  {error}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant='contained' color="secondary">
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
}

export default ErrorHorarioDialog;