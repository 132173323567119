import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imagen: {
    width: '100%',
    height: '200px',
    marginBottom: '20px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  imageUploaderContainer: {
    textAlign: 'center',
  },
}));

const ImageUploader = ({
  foto,
  onChange
}) => {

  const classes = useStyles();

  return (
    <Grid className={classes.imageUploaderContainer}>
      <div style={{ backgroundImage: `url(${foto.url})` }} className={classes.imagen} />
      <input
        accept="image/*"
        id="contained-button-file"
        type="file"
        hidden
        onChange={onChange}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="secondary" component="span">
          Cambiar foto
        </Button>
      </label>
    </Grid>
  )
}

export default ImageUploader;