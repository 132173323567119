import React from 'react';
import { Grid, Typography, makeStyles, Divider } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: '800'
  },
  green: {
    color: 'green'
  },
  end: {
    textAlign: 'end'
  }
}));


const ReporteCantPedidosCard = ({
  resumen
}) => {

  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={5}>
      <Grid item direction="column" xs={12} md={3} style={{ textAlign: 'center' }}>
        <Grid item>
          <Typography variant="h6" >Pedidos totales realizados</Typography>
        </Grid>
        <Grid item >
          <Typography variant="h5" className={classes.bold + ' ' + classes.green}>{resumen.pedidosTotales}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="button" className={classes.bold}> Pedidos por la app</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{resumen.pedidosApp}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="subtitle2" className={classes.bold}>En efectivo</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{resumen.pedidosAppEfectivo}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="subtitle2" className={classes.bold}>Con tarjeta</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{resumen.pedidosAppTarjeta}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="button" className={classes.bold}> Pedidos externos</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{resumen.pedidosExternos}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="subtitle2" className={classes.bold}>En efectivo</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{resumen.pedidosExternosEfectivo}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="subtitle2" className={classes.bold}>Con tarjeta</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{resumen.pedidosExternosTarjeta}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReporteCantPedidosCard;