import { makeStyles, TextField } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: '20px'
  }
}));

const FormTextField = ({
  value,
  onChange,
  label,
  placeholder,
  ...props
}) => {
  
  const classes = useStyles();

  return (
    <TextField
      size="small"
      {...props}
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      placeholder={placeholder}
      variant={'outlined'}
      className={classes.input}
    />
  )
}

export default FormTextField;