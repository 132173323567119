import React, { useEffect, useState } from 'react';
import { Button, Grid, InputAdornment, makeStyles, Switch, FormControlLabel, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AttachMoney from '@material-ui/icons/AttachMoney';
import FormTextField from './FormTextField';
import FormHeader from './FormHeader';
import ImageUploader from './ImageUploader';
import FormIngredientesProducto from './FormIngredientesProducto';
import { toBase64 } from '../../../utils/fileUtils';
import { getProducto } from '../../../api/productosApi';
import ConfirmarDialog from '../../common/ConfirmarDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '15px'
  },
  buttonContainer: {
    justifyContent: 'center',
    marginTop: '15px'
  },
  caption: {
    letterSpacing: '1px'
  },
  alert: {
    marginBottom: '15px'
  }
}));

const defaultFoto = {
  base64: null,
  url: `${process.env.REACT_APP_BASE_URL_FASTER_API}/storage/images/productos/sin-imagen.png`,
  modificada: false,
  file: null
}

const FormProducto = ({
  productoId,
  onGuardar,
  onCancelar,
  setLoading,
  loading,
  negocioId,
  onEliminar
}) => {

  const classes = useStyles();

  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [precio, setPrecio] = useState(1);
  const [foto, setFoto] = useState(defaultFoto);
  const [destacado, setDestacado] = useState(false);
  const [producto, setProducto] = useState(null);
  const [ingredientes, setIngredientes] = useState([]);
  const [ingredientesGlobales, setIngredientesGlobales] = useState([]);
  const [confirmarCambios, setConfirmarCambios] = useState(false);
  const [eliminarProducto, setEliminarProducto] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (productoId) {
      getProducto(productoId).then(response => {
        setTitulo(response.data.titulo);
        setDescripcion(response.data.descripcion);
        setPrecio(response.data.precio);
        setDestacado(response.data.destacado);
        setFoto({
          ...defaultFoto,
          url: `${process.env.REACT_APP_BASE_URL_FASTER_API}/${response.data.imagen}`,
        })
        setProducto(response.data);
        setIngredientes(response.data.ingredientes.filter(ti => !ti.tipo.global));
        setIngredientesGlobales(response.data.ingredientes.filter(ti => !!ti.tipo.global));
        setLoading(false);
      });
    } else {
      clearProducto();
      setLoading(false);
    }
  }, [productoId]); // eslint-disable-line

  const clearProducto = () => {
    setTitulo('');
    setDescripcion('');
    setPrecio(1);
    setFoto(defaultFoto);
    setDestacado(false);
    setProducto(null);
    setIngredientes([]);
  }

  const handleFotoChange = async (event) => {
    setLoading(true);
    let file = event.target.files[0];
    setFoto({
      base64: await toBase64(file),
      file: file,
      url: URL.createObjectURL(file),
      modificada: true
    })
    setLoading(false);
  }

  const handleSubmit = () => {
    if (titulo !== '') {
      setLoading(true);
      let ingredientesBody = [];
      ingredientes.map(ti => {
        let ingredientesConProductoId = ti.ingredientes.map(i => ({
          nombre: i.nombre,
          disponible: i.disponible,
          precio: i.precio,
          tipo_ingrediente_id: i.tipo_ingrediente_id,
          producto_id: productoId
        }));

        ingredientesBody = [
          ...ingredientesBody,
          ...ingredientesConProductoId
        ];

        return -1;
      });

      let ingredientesGlobalesBody = ingredientesGlobales.map(ti => ti.tipo.id);
      let productoBody = {
        titulo,
        descripcion,
        precio: parseFloat(precio),
        destacado,
        ingredientes: ingredientesBody,
        globales: ingredientesGlobalesBody,
        imagen: foto.modificada ? foto.base64.substring(foto.base64.indexOf(",") + 1, foto.base64.length) : null
      };
      onGuardar(productoBody);
    }
  }

  return (
    <Grid container direction='column' className={classes.container}>
      <FormHeader producto={producto} onCancelar={onCancelar} />
      {
        producto && producto.pendiente &&
        <Alert className={classes.alert} severity="warning">Este producto se encuentra en revisión.</Alert>
      }
      <ImageUploader foto={foto} onChange={handleFotoChange} />
      <FormTextField
        label={'Producto'}
        onChange={setTitulo}
        value={titulo}
        placeholder={'Nombre de producto'}
      />
      <FormTextField
        label={'Descripcion'}
        value={descripcion}
        multiline
        onChange={setDescripcion}
        placeholder={'Descripcion de producto'}
      />
      <Grid container justify='space-between'>
        <Grid item xs={12} sm={5} className={classes.input} >
          <FormTextField
            label={"Precio app"}
            type={'number'}
            value={precio}
            onChange={setPrecio}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoney />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.input} >
          <FormControlLabel
            control={<Switch color="primary" checked={destacado} onChange={(e) => setDestacado(e.target.checked)} />}
            label={<Typography className={classes.caption} variant='subtitle2'>Destacar producto?</Typography>}
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      <FormIngredientesProducto
        globales={false}
        ingredientes={ingredientes}
        setIngredientes={setIngredientes}
        loading={loading}
        setLoading={setLoading}
        negocioId={negocioId}
      />
      <FormIngredientesProducto
        globales={true}
        ingredientes={ingredientesGlobales}
        setIngredientes={setIngredientesGlobales}
        loading={loading}
        setLoading={setLoading}
        negocioId={negocioId}
      />
      <Grid container direction="row" >
        <Grid item xs={12} md={productoId ? 6 : 12} >
          <Grid container className={classes.buttonContainer}>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => handleSubmit()}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
        {
          productoId &&
          <Grid item xs={12} md={6} >
            <Grid container className={classes.buttonContainer}>
              <Button
                variant='contained'
                color='#000'
                size='large'
                onClick={() => setEliminarProducto(true)}
              >
                Eliminar
              </Button>
            </Grid>
          </Grid>
        }
      </Grid>
      {
        confirmarCambios &&
        <ConfirmarDialog
          handleClose={() => setConfirmarCambios(false)}
          handleConfirm={() => {
            handleSubmit();
            setConfirmarCambios(false);
          }}
          message="Recordá que al guardar los cambios, el producto pasará a estar en REVISIÓN hasta que sea aprobado por un administrador de Faster. Mientras tanto, el producto NO ESTARÁ DISPONIBLE en la aplicación."
        />
      }
      {
        eliminarProducto &&
        <ConfirmarDialog
          handleClose={() => setEliminarProducto(false)}
          handleConfirm={() => {
            onEliminar();
            setEliminarProducto(false);
          }}
          message="Estás a punto de eliminar el producto seleccionado. Esta acción no se puede revertir y el producto dejará de aparecer en la aplicacion de Faster."
        />
      }
    </Grid>
  )
}

export default FormProducto;