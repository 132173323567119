import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, makeStyles } from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HomeIcon from '@material-ui/icons/Home';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SidebarItems = () => {
  return (
    <List>
      <SidebarListItem
        path={'/home'}
        icon={() => <HomeIcon />}
        label={'Inicio'}
      />
      <SidebarListItem
        path={'/productos'}
        icon={() => <FastfoodIcon />}
        label={'Productos'}
      />
      <SidebarListItem
        path={'/tipos-ingredientes'}
        icon={() => <MenuBookIcon />}
        label={'Tipos de ingredientes'}
      />
      <SidebarListItem
        path={'/horarios'}
        icon={() => <AccessTimeIcon />}
        label={'Horarios'}
      />
      <SidebarCollapsableList
        label={'Reportes'}
        icon={() => <AssessmentIcon />}
      >
        <SidebarListItem
          path={'/reportes/pedidos'}
          icon={() => <FormatListNumberedIcon />}
          label={'Pedidos'}
          nested
        />
        <SidebarListItem
          path={'/reportes/cadetes'}
          icon={() => <DirectionsBikeIcon />}
          label={'Cadetes'}
          nested
        />
      </SidebarCollapsableList>
    </List>
  )
}

export default SidebarItems;

const SidebarCollapsableList = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          {props.icon()}
        </ListItemIcon>
        <ListItemText primary={props.label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.children}
        </List>
      </Collapse>
    </>
  )
}

const SidebarListItem = ({
  path,
  icon,
  label,
  nested = false
}) => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();

  return (
    <ListItem
      style={{ backgroundColor: location.pathname.includes(path) ? '#f2f2f2' : 'inherit' }}
      button
      onClick={() => history.push(path)}
      className={nested ? classes.nested : ''}
    >
      <ListItemIcon>{icon()}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  )
}