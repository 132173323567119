import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TablePagination, Button, Paper } from '@material-ui/core';
import "./style.css";
import ModalPedido from "./ModalPedido";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto"
  },
  table: {
    width: "100%",
    minWidth: "90%"
  }
}));

const TablaCadetes = ({
  cadetes
}) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 30));
    setPage(0);
  };

  const tableHead = [
    'Nombre del cadete',
    'Pedidos realizados',
    'Envíos en Efectivo',
    'Envíos con Tarjeta',
    'Descuentos en efectivo a reintegrar',
    'Rendición'
  ];

  const classes = useStyles();
  return (
    <div className="tablaReporte">
      <Paper className={classes.root}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {tableHead.map((th, i) => <TableCell style={{fontWeight: 700}} component="th" scope="row" key={i} align="center">{th}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {cadetes.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
              .map((cadete, key) => (
                <TableRow key={key}>
                  <TableCell align="center">{cadete.nombre}</TableCell>
                  <TableCell align="right">{cadete.pedidos_realizados}</TableCell>
                  <TableCell align="right">{cadete.envios_efectivo}</TableCell>
                  <TableCell align="right">{cadete.envios_tarjeta}</TableCell>
                  <TableCell align="right">{cadete.descuentos_reintegro}</TableCell>
                  <TableCell align="right">{cadete.rendicion}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[30]}
          component="div"
          count={cadetes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default TablaCadetes;
