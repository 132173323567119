import React, { useContext } from 'react';
import Login from '../pages/auth/Login';
import Productos from '../pages/productos/Productos';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Middleware from './Middleware';
import Home from '../pages/home/Home';
import { AuthContext } from '../context/AuthContextProvider';
import Navbar from './Navbar';
import Sidebar from './sidebar/Sidebar';
import { Grid } from '@material-ui/core';
import TiposIngredientes from '../pages/tipos-ingredientes/TiposIngredientes';
import Horarios from '../pages/horarios/Horarios';
import ReportesPedidos from '../pages/reportes/ReportesPedidos';
import ReportesCadetes from '../pages/reportes/ReportesCadetes';

const Router = () => {

  const { authenticated, mounted, negocioActivo } = useContext(AuthContext);

  const isAuth = () => authenticated && negocioActivo;

  return (
    <Grid container direction='column'>
      {
        isAuth() && <Navbar />
      }
      {
        mounted &&
        <BrowserRouter>
          <Grid container direction='row'>
            <Grid item xs={2}>
              {
                isAuth() && <Sidebar />
              }
            </Grid>
            <Grid item xs={isAuth() ? 10 : 12}>
              <Switch>
                <Middleware exact path="/" component={Home} />
                <Middleware exact path="/home" component={Home} />
                <Middleware exact path="/productos" component={Productos} />
                <Middleware exact path="/tipos-ingredientes" component={TiposIngredientes} />
                <Middleware exact path="/horarios" component={Horarios} />
                <Middleware exact path="/reportes/pedidos" component={ReportesPedidos} />
                <Middleware exact path="/reportes/cadetes" component={ReportesCadetes} />
                <Route exact path="/login" component={Login} />
              </Switch>
            </Grid>
          </Grid>
        </BrowserRouter>
      }
    </Grid>)
}

export default Router;