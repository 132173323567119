import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Create';
import NuevoTipoIngredienteDialog from '../dialogs/NuevoTipoIngredienteDialog';
import IngredienteDialog from '../dialogs/IngredienteDialog';
import { sortIngredientes, sortTiposIingredientesGroup, getTipoIngredienteSubtitle } from '../../../utils/ingredientesUtils';
import moment from 'moment';
import { createTipoIngrediente } from '../../../api/tiposIngredientesApi';
import { getTiposIngredientes } from '../../../api/negociosApi';
import AddRoundedIcon from '@material-ui/icons/AddRounded'

const useStyles = makeStyles(() => ({
  tipoIngredienteTitle: {
    fontWeight: 600,
    textTransform: 'capitalize'
  },
  ingredientesTitle: {
    marginBottom: '10px'
  },
  card: {
    marginBottom: '20px'
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'row-reverse',
    maxWidth: 'fit-content'
  },
  listItemSubtitle: {
    marginRight: '5px',
    cursor: 'default'
  },
  listItemIcon: {
    minWidth: 'fit-content'
  }
}));

const FormIngredientesProducto = ({
  globales,
  setIngredientes,
  ingredientes,
  setLoading,
  negocioId
}) => {

  const [tiposIngredientes, setTiposIngredientes] = useState([]);
  const [showDialogFormIngrediente, setShowDialogFormIngrediente] = useState(false);
  const [showDialogFormTipoIngrediente, setShowDialogFormTipoIngrediente] = useState(false);
  const [ingredienteSelected, setIngredienteSelected] = useState(null);
  const [tipoIngredienteSelected, setTipoIngredienteSelected] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    fetchTiposIngredientes();
  }, []); // eslint-disable-line

  const fetchTiposIngredientes = () => {
    setLoading(true);
    getTiposIngredientes(negocioId, globales).then((res) => {
      setTiposIngredientes(res.data);
      setLoading(false);
    })
  }

  const agregarIngrediente = (nombre, precio, disponible, tipoId) => {
    let newIngrediente = {
      nombre,
      precio,
      disponible,
      tipo_ingrediente_id: tipoId,
      id: parseInt(moment().unix() + "" + moment().get('ms'))
    };

    let tipoGroup = { ...ingredientes.find(ti => ti.tipo.id === tipoId) };

    let newIngredientes = [
      ...tipoGroup.ingredientes,
      newIngrediente
    ].sort(sortIngredientes);

    setIngredientes([
      ...ingredientes.filter(ti => ti.tipo.id !== tipoId),
      {
        tipo: tipoGroup.tipo,
        ingredientes: newIngredientes
      }
    ].sort(sortTiposIingredientesGroup));
  }

  const eliminarIngrediente = (ingredienteId, tipoId) => {
    let tipoGroup = { ...ingredientes.find(ti => ti.tipo.id === tipoId) };
    setIngredientes([
      ...ingredientes.filter(ti => ti.tipo.id !== tipoId),
      {
        tipo: tipoGroup.tipo,
        ingredientes: tipoGroup.ingredientes.filter(i => i.id !== ingredienteId).sort(sortIngredientes)
      }
    ].sort(sortTiposIingredientesGroup));
  }

  const editarIngrediente = (nombre, precio, disponible, ingredienteId, tipoId) => {
    let tipoGroup = { ...ingredientes.find(ti => ti.tipo.id === tipoId) };
    let newIngrediente = {
      nombre,
      precio,
      disponible,
      tipo_ingrediente_id: tipoId,
      id: parseInt(moment().unix() + "" + moment().get('ms'))
    };
    setIngredientes([
      ...ingredientes.filter(ti => ti.tipo.id !== tipoId),
      {
        tipo: tipoGroup.tipo,
        ingredientes: [
          ...tipoGroup.ingredientes.filter(i => i.id !== ingredienteId),
          newIngrediente
        ].sort(sortIngredientes)
      }
    ].sort(sortTiposIingredientesGroup));
  }

  const editarDisponibilidadIngrediente = (ingrediente, disponible, tipoId) => {
    editarIngrediente(ingrediente.nombre, ingrediente.precio, disponible, ingrediente.id, tipoId);
  }

  const eliminarTipoIngrediente = (tipoId) => {
    setIngredientes([
      ...ingredientes.filter(ti => ti.tipo.id !== tipoId)
    ]);
  }

  const onSubmitTipoIngrediente = (tipo) => {
    setLoading(true);
    if (tipo.id) {
      submitTipoIngredienteCallback(tipo, tipo.id);
    } else {
      //Creamos un nuevo tipo de ingrediente pegandole a la api
      createTipoIngrediente({ ...tipo, negocioId }).then((res) => submitTipoIngredienteCallback(tipo, res.data.id));
    }
  }

  const submitTipoIngredienteCallback = (tipo, id) => {
    //Guardamos el nuevo tipo de ingrediente como un grupo {tipo, ingredientes = []}
    setIngredientes([
      ...ingredientes,
      {
        tipo: {
          prefijo: null,
          minimo: tipo.minimo,
          maximo: tipo.maximo,
          id: id,
          nombre: tipo.nombre,
          repetible: tipo.repetible
        },
        ingredientes: []
      }
    ]);
    setLoading(false);
  }

  return (
    <Grid container direction='column'>
      <Typography variant="h6" className={classes.ingredientesTitle}>
        {globales ? 'Ingredientes globales del producto' : 'Ingredientes del producto'}
      </Typography>
      {
        ingredientes.map((ti) => {
          return (
            <Card key={'card-tipo-ing-' + ti.tipo.id} className={classes.card}>
              <CardHeader
                action={
                  <>
                    {
                      !globales &&
                      <IconButton aria-label="add" size='small' onClick={() => {
                        setTipoIngredienteSelected(ti.tipo);
                        setIngredienteSelected(null);
                        setShowDialogFormIngrediente(true);
                      }}>
                        <AddIcon />
                      </IconButton>
                    }
                    <IconButton aria-label="remove" size='small' onClick={() => {
                      eliminarTipoIngrediente(ti.tipo.id);
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
                title={
                  <Typography variant="body2" className={classes.tipoIngredienteTitle}>
                    {ti.tipo.nombre}
                  </Typography>
                }
                subheader={
                  <Typography color='textSecondary' variant="body2">
                    {getTipoIngredienteSubtitle(ti.tipo)}
                  </Typography>
                }
              />
              <List>
                {
                  ti.ingredientes.map((ingrediente, key) => {
                    return (
                      <React.Fragment key={key}>
                        <ListItem dense >
                          {
                            !globales &&
                            <ListItemIcon className={classes.listItemIcon}>
                              <Checkbox
                                size='small'
                                edge="start"
                                checked={ingrediente.disponible}
                                tabIndex={-1}
                                color="primary"
                                onChange={() => {
                                  setTipoIngredienteSelected(ti.tipo);
                                  setIngredienteSelected(ingrediente);
                                  editarDisponibilidadIngrediente(ingrediente, !ingrediente.disponible, ti.tipo.id);
                                }}
                                disableRipple
                              />
                            </ListItemIcon>
                          }
                          <ListItemText
                            className={classes.listItemText}
                            secondary={ti.tipo.prefijo ? ti.tipo.prefijo : ''}
                            secondaryTypographyProps={{ className: classes.listItemSubtitle }}
                            primary={ti.tipo.prefijo ? ingrediente.nombre.toLowerCase() : ingrediente.nombre + (ingrediente.precio && ingrediente.precio > 0 ? " ($" + ingrediente.precio + ")" : '')}
                          />
                          {
                            !globales &&
                            <ListItemSecondaryAction>
                              <IconButton size="small" edge="end" aria-label="edit" onClick={() => {
                                setTipoIngredienteSelected(ti.tipo);
                                setIngredienteSelected(ingrediente);
                                setShowDialogFormIngrediente(true);
                              }}>
                                <EditIcon />
                              </IconButton>
                              <IconButton size="small" edge="end" aria-label="delete" onClick={() => {
                                // setTipoIngredienteSelected(ti.tipo);
                                // setIngredienteSelected(ingrediente);
                                eliminarIngrediente(ingrediente.id, ti.tipo.id);
                              }}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          }
                        </ListItem>
                        {
                          key !== ti.ingredientes.length - 1 &&
                          <Divider />
                        }
                      </React.Fragment>
                    )
                  }
                  )
                }

              </List>
            </Card>
          )
        })
      }
      <Button
        color='primary'
        onClick={() => {
          setShowDialogFormTipoIngrediente(true);
          setTipoIngredienteSelected(null);
        }}
        startIcon={<AddRoundedIcon />}
      >
        {globales ? 'Agregar tipo de ingrediente global' : 'Agregar tipo de ingrediente'}
      </Button>
      {
        showDialogFormTipoIngrediente &&
        <NuevoTipoIngredienteDialog
          globales={globales}
          tiposNegocio={tiposIngredientes}
          ingredientesActuales={ingredientes}
          handleClose={() => setShowDialogFormTipoIngrediente(false)}
          handleSubmit={onSubmitTipoIngrediente}
        />
      }
      {
        showDialogFormIngrediente &&
        <IngredienteDialog
          ingrediente={ingredienteSelected}
          handleClose={() => setShowDialogFormIngrediente(false)}
          handleSubmit={(nombre, precio) => {
            if (ingredienteSelected) {
              editarIngrediente(nombre, precio, ingredienteSelected.disponible, ingredienteSelected.id, tipoIngredienteSelected.id);
            } else {
              agregarIngrediente(nombre, precio, true, tipoIngredienteSelected.id);
            }
          }}
        />
      }
    </Grid>
  )
}

export default FormIngredientesProducto;