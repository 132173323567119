import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { getStatus, updateCerradoMomentaneo } from '../../api/homeApi';
import Loader from '../../components/common/Loader';
import { AuthContext } from '../../context/AuthContextProvider';
import StatusDot from '../../components/common/StatusDot';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HistoryIcon from '@material-ui/icons/History';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '100px',
    marginLeft: '20px',
    marginRight: '20px'
  },
  title: {
    marginBottom: '30px',
  },
  title2: {
    fontSize: '20px',
    fontWeight: '700'
  },
  paper: {
    padding: '25px'
  },
  text: {
    fontSize: '18px',
  },
  button: {
    marginTop: '15px'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: '10px',
    color: theme.palette.primary.main
  }
}));

const Home = () => {
  const classes = useStyles();
  const { negocioActivo } = useContext(AuthContext);

  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (negocioActivo) {
      fetchStatus();
    }
  }, [negocioActivo]); // eslint-disable-line

  const fetchStatus = () => {
    setLoading(true);
    getStatus(negocioActivo.id).then((res) => {
      setStatus(res.data);
      console.log(res.data);
    }).finally(() => setLoading(false));
  }

  const toggleCerrarMomentaneamente = () => {
    setLoading(true);
    let cerradoAhora = false;
    if (status.cerrarMomentaneamente) {
      cerradoAhora = true;
    }
    updateCerradoMomentaneo(negocioActivo.id, !cerradoAhora).then(() => {
      fetchStatus();
      toast.success("Disponibilidad actualizada");
    }).catch(() => {
      toast.error("Error al actualizar disponibilidad");
      setLoading(false);
    });
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h5" className={classes.title}>
        ¡Bienvenido!
      </Typography>
      <Grid container spacing={10}>
        {
          loading ? <Loader /> : <>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <div className={classes.title2}>
                  Disponibilidad
                </div>
                <div className={classes.text}>
                  {
                    (status.abierto && !status.cerrarMomentaneamente) ?
                      <span>
                        <StatusDot color="#2fd12f" />
                        Tu negocio se encuentra abierto
                        </span> :
                      status.cerrarMomentaneamente ?
                        <span>
                          <StatusDot color="red" />
                        Tu negocio se encuentra cerrado momentáneamente
                      </span> :
                        <span>
                          <StatusDot color="red" />
                        Tu negocio se encuentra cerrado
                      </span>
                  }
                </div>
                {
                  (status.abierto) &&
                    status.cerrarMomentaneamente ?
                    <Button onClick={toggleCerrarMomentaneamente} variant='contained' color="primary" className={classes.button}>
                      Activar
                  </Button> :
                    <Button onClick={toggleCerrarMomentaneamente} variant='contained' color="primary" className={classes.button}>
                      Cerrar momentáneamente
                  </Button>
                }
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <div className={classes.title2}>
                  Pedidos
              </div>
                <div className={classes.text}>
                  <WhatshotIcon className={classes.icon} />
                  Activos: {status.pedidos.activos}
                </div>
                <div className={classes.text}>
                  <AssignmentTurnedInIcon className={classes.icon} />
                  Completados hoy: {status.pedidos.completados}
                </div>
                <div className={classes.text}>
                  <DateRangeIcon className={classes.icon} />
                  Esta semana: {status.pedidos.semana}
                </div>
                <div className={classes.text}>
                  <HistoryIcon className={classes.icon} />
                  Historico: {status.pedidos.historico}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6}>
            </Grid>
          </>
        }
      </Grid>
    </div>
  )
}

export default Home;