import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Avatar, Button } from '@material-ui/core'
import SidebarItems from './SidebarItems';
import { AuthContext } from '../../context/AuthContextProvider';
import { FASTERAPIURL } from '../../api/apiService';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SelectNegocio from '../../components/login/SelectNegocio';
import { saveNegocioActivo } from '../../utils/authUtils';

const useStyles = makeStyles((theme) => ({
  desktopContainer: {
    paddingTop: "5rem",
    borderRight: "1px solid #e8e8e8",
    boxShadow: "-5px 0px 12px 0px",
    backgroundColor: 'white',
    minHeight: '100vh',
    position: "fixed"
    // width: 'fit-content'
  },

  title: {
    textAlign: 'center',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '10px'
  },
  avatar: {
    width: '64px',
    height: '64px'
  },
  logout: {
    fontWeight: 800,
    marginTop: '15px'
  },
  fit: {
    // maxWidth: 'fit-content'
  }
}));

const Desktop = () => {
  const classes = useStyles();
  const { negocioActivo, logout, user, setNegocioActivo } = useContext(AuthContext);

  const changeNegocio = (negocio) => {
    setNegocioActivo(negocio);
    saveNegocioActivo(negocio);
    window.location.reload(false)
  }

  return (
    <div className={classes.desktopContainer}>
      <Grid className={classes.fit} container direction="column" alignItems='center'>
        <Typography component='span' className={classes.title} variant="h5" color="primary">
          {negocioActivo.nombre}
        </Typography>
        <Avatar className={classes.avatar} src={FASTERAPIURL + negocioActivo.imagen} />
        <Button className={classes.logout} onClick={logout} color="primary" startIcon={<ExitToAppIcon />}>SALIR</Button>
        {user.negocios.length > 1 && <SelectNegocio negocioActivo={negocioActivo} classes={classes} user={user} onConfirmNegocio={changeNegocio} sidebar />}
      </Grid>
      <SidebarItems />
    </div>
  );
}

export default Desktop;
