import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, TextField } from '@material-ui/core';
import AttachMoney from '@material-ui/icons/AttachMoney';

const NewHorarioDialog = ({
  handleClose,
  handleSubmit,
  ingrediente
}) => {

  const [nombre, setNombre] = useState('');
  const [precio, setPrecio] = useState(0);

  useEffect(() => {
    if (ingrediente) {
      setNombre(ingrediente.nombre);
      setPrecio(ingrediente.precio);
    } else {
      setNombre('');
      setPrecio(0);
    }
  }, [ingrediente]);

  return <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{ingrediente ? 'Editar ingrediente' : 'Crear ingrediente'}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Por favor, ingrese el nombre del ingrediente y, en caso de tener un valor adicional, su precio.
        En caso contrario, dejar en 0.
    </DialogContentText>
      <TextField
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
        margin="dense"
        label="Nombre del ingrediente"
        fullWidth
      />
      <TextField
        value={precio}
        onChange={(e) => setPrecio(e.target.value)}
        margin="dense"
        type='number'
        label="Precio del ingrediente"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoney />
            </InputAdornment>
          ),
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Cancelar
    </Button>
      <Button onClick={() => {
        handleSubmit(nombre, precio, ingrediente ? ingrediente.disponible : true);
        handleClose();
      }} variant='contained' color="secondary">
        {ingrediente ? 'EDITAR' : 'CREAR'}
      </Button>
    </DialogActions>
  </Dialog>
}

export default NewHorarioDialog;