import './App.css';
import { ThemeProvider, StylesProvider, CssBaseline } from '@material-ui/core';
import { AuthContextProvider } from './context/AuthContextProvider';
import Router from './navigation/Router';
import theme from '../src/styles/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

moment.locale('es');

const App = () => {
  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthContextProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ToastContainer />
            <Router />
          </MuiPickersUtilsProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
