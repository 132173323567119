import React, { useEffect, useRef, useState } from 'react';
import { Grid, IconButton, InputBase, makeStyles } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) => ({
  inputTipoSelected: {
    cursor: 'pointer',
    borderRadius: '2px',
    padding: '2px',
    border: '1px transparent solid',
    '&:hover': {
      border: '1px gray solid',
    },
    '&:focus': {
      border: '1px gray solid',
    },
    minWidth: '100%'
  },
  productosColumnHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px',
    paddingLeft: '20px'
  }
}));

const ProductosColumnHeader = ({
  onSubmit,
  categoria
}) => {

  const classes = useStyles();
  const inputProductoRef = useRef(null);

  const [categoriaNueva, setCategoriaNueva] = useState(categoria.nombre);

  useEffect(() => {
    setCategoriaNueva(categoria.nombre);
  }, [categoria]);

  const onSubmitNuevaCategoria = () => {
    if (categoria.nombre !== categoriaNueva && categoriaNueva !== '') {
      onSubmit(categoriaNueva, categoria.id);
    }
  }

  return (
    <Grid container className={classes.productosColumnHeader}>
      <Grid item xs>
        <InputBase
          ref={inputProductoRef}
          value={categoriaNueva}
          onChange={(e) => setCategoriaNueva(e.target.value)}
          onKeyPress={e => e.key === 'Enter' && onSubmitNuevaCategoria()}
          className={classes.inputTipoSelected}
        />
      </Grid>
      <Grid item xs={1}>
        {
          categoria.nombre !== categoriaNueva && categoriaNueva !== '' &&
          <IconButton size="small" edge="end" aria-label="edit" onClick={onSubmitNuevaCategoria}>
            <SaveIcon />
          </IconButton>
        }
      </Grid>
      <Grid item xs={1}>
        <IconButton size="small" edge="end" aria-label="edit" onClick={() => inputProductoRef.current.focus()}>
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default ProductosColumnHeader;