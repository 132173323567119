import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContextProvider";

const Middleware = ({ component: Component, path, ...rest }) => {
  
  const { authenticated, negocioActivo } = React.useContext(AuthContext);

  const isAllowed = () => authenticated && negocioActivo;

  return isAllowed()  ? (
    <Route {...rest} render={() => <Component />} />
  ) : (
    <Redirect to="/login" />
  );
};

export default Middleware;