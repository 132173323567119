import React from 'react';
import { Grid, Paper, Button, makeStyles } from '@material-ui/core';
import ReporteMontoPedidosCard from './ReporteMontoPedidosCard';
import ReporteCantPedidosCard from './ReporteCantPedidosCards';
import TablaPedidos from '../tablas/TablaPedidos';

const useStyles = makeStyles((theme) => ({
  title2: {
    fontSize: '20px',
    fontWeight: '700'
  },
  paper: {
    padding: '25px'
  },
  button: {
    marginTop: '15px'
  }
}));


const ReportesPedidosCards = ({
  cleanReportes,
  reportes,
  startDate,
  endDate
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container justify='space-between'>
            <div className={classes.title2}>
              {`Resumen: ${startDate.format('DD/MM/YYYY')} - ${endDate.format('DD/MM/YYYY')}`}
            </div>
            <Button onClick={cleanReportes} variant='contained' color="primary" className={classes.button}>
              Limpiar reporte
            </Button>
          </Grid>
          <ReporteCantPedidosCard resumen={reportes.resumen} />
          <ReporteMontoPedidosCard resumen={reportes.resumen} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.title2}>
            Pedidos
          </div>
          <TablaPedidos
            pedidos={reportes.pedidos}
          />
        </Paper>
      </Grid>
    </>
  )
}

export default ReportesPedidosCards;