import React, { useState } from 'react';
import { Grid, Paper, Button, makeStyles } from '@material-ui/core';
import { DateRangePicker } from 'react-dates';
import './react-dates.css';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

const today = moment();

const useStyles = makeStyles((theme) => ({
  title2: {
    fontSize: '20px',
    fontWeight: '700'
  },
  paper: {
    padding: '25px'
  },
  text: {
    fontSize: '18px',
  },
  button: {
    marginTop: '15px'
  }
}));

const presets = [
  {
    text: 'Ayer',
    start: today.clone().subtract(1, 'day'),
    end: today.clone().subtract(1, 'day')
  },
  {
    text: 'Esta semana',
    start: today.clone().startOf('week'),
    end: today.clone()
  },
  {
    text: 'Semana pasada',
    start: today.clone().startOf('week').subtract(1, 'week'),
    end: today.clone().startOf('week').subtract(1, 'day')
  },
  {
    text: 'Este mes',
    start: today.clone().startOf('month'),
    end: today.clone()
  },
  {
    text: 'Mes anterior',
    start: today.clone().startOf('month').subtract(1, 'month'),
    end: today.clone().startOf('month').subtract(1, 'day')
  },
  {
    text: 'Ultimos 3 meses',
    start: today.clone().startOf('month').subtract(3, 'month'),
    end: today.clone().startOf('month').subtract(1, 'day')
  }
];

const DesdeHastaPicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  generarReportes,
  loading
}) => {

  const [focused, setFocused] = useState('startDate');
  const classes = useStyles();

  const renderPresets = () => <Grid>
    {
      presets.map((value, index) => {
        return <Button color="primary" index={index} onClick={() => {
          setStartDate(value.start);
          setEndDate(value.end);
        }}>
          {value.text}
        </Button>
      })
    }
  </Grid>

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <div className={classes.title2}>
          Seleccionar periodo
        </div>
        <div className={classes.text}>
          <DateRangePicker
            startDate={startDate} startDateId="reporte_desde"
            endDate={endDate} endDateId="reporte_hasta"
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            disabled={loading}
            focusedInput={focused} onFocusChange={focused => setFocused(focused)}
            isOutsideRange={() => false}
            showDefaultInputIcon
            displayFormat="DD/MM/YYYY"
            showClearDates
            minimumNights={0}
            reopenPickerOnClearDates
            numberOfMonths={isMobile ? 1 : 3}
            hideKeyboardShortcutsPanel
            horizontalWidht=''
            calendarInfoPosition="bottom" renderCalendarInfo={renderPresets}  
            startDatePlaceholderText={'Desde'}
            endDatePlaceholderText={'Hasta'}
          />
        </div>
        <Button disabled={!startDate || !endDate || loading} onClick={generarReportes} variant='contained' color="primary" className={classes.button}>
          Generar reporte
        </Button>
      </Paper>
    </Grid>
  )
}

export default DesdeHastaPicker;