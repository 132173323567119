import React from 'react';
import { makeStyles, Dialog, DialogContent, DialogActions, Button, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  property: {
    fontWeight: '800',
    marginRight: '10px'
  }
}));

const ModalPedido = ({
  pedido,
  handleClose
}) => {
  const classes = useStyles();

  const getProductos = () => {
    let id = pedido.items[0].producto_id;
    let detalle = pedido.items[0].detalles;
    let counts = [];
    let count = 0;
    let indice = -1;
    pedido.items.map(item => {
      if (id === item.producto_id && detalle === item.detalles) {
        count++;
        indice++;
      } else {
        counts.push({ count: count, index: indice, detalle: detalle });
        count = 1;
        detalle = item.detalles;
        indice++;
        id = item.producto_id;
      }
    });
    counts.push({ count: count, index: indice, detalle: detalle });
    return counts.map((count, key) => {
      return (
        <p key={key}>
          {pedido.items[count.index].producto
            ? pedido.items[count.index].producto.titulo
            : pedido.items[count.index].detalle}{" "}
          x{count.count}
          <br></br> > {count.detalle}
        </p>
      );
    });
  };

  const getTotal = () => {
    return pedido.total + pedido.precio_envio - pedido.descuento;
  }

  return <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogContent>
      <Grid container>
        <Grid item xs={6}>
          <Grid container direction="row">
            <Typography component="h1" variant="h6" className={classes.property}>ID Pedido</Typography>
            <Typography component="h1" variant="h6">{pedido.id}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row">
            <Typography component="h1" variant="h6" className={classes.property}>Fecha</Typography>
            <Typography component="h1" variant="h6">{moment(pedido.created_at).format('DD/MM/YYYY HH:mm')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row">
            <Typography component="h1" variant="h6" className={classes.property}>Cliente</Typography>
            <Typography component="h1" variant="h6">{pedido.user && `${pedido.user.nombre} ${pedido.user.apellido} (ID: ${pedido.user_id})`}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row">
            <Typography component="h1" variant="h6" className={classes.property}>Dirección</Typography>
            <Typography component="h1" variant="h6">{pedido.direccion_entrega}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h1" variant="h6" className={classes.property}>{pedido.tipo === 'Negocio' ? 'Productos' : 'Detalle'}</Typography>
          <Typography component="h1" variant="h6">{pedido.tipo === 'Negocio' ? getProductos() : pedido.detalle_pedido}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid justify={'space-between'} direction="row" container>
            <Typography component="h1" variant="h6" className={classes.property}>Subtotal</Typography>
            <Typography component="h1" variant="h6">{`$${pedido.total}`}</Typography>
          </Grid>
          <Grid justify={'space-between'} direction="row" container>
            <Typography component="h1" variant="h6" className={classes.property}>Envio</Typography>
            <Typography component="h1" variant="h6">{`$${pedido.precio_envio}`}</Typography>
          </Grid>
          {
            pedido.descuento > 0 &&
            <Grid justify={'space-between'} direction="row" container>
              <Typography component="h1" variant="h6" className={classes.property}>Cupón/Descuento</Typography>
              <Typography component="h1" variant="h6">{`-$${pedido.descuento}`}</Typography>
            </Grid>
          }
          <Divider />
          <Grid justify={'space-between'} direction="row" container>
            <Typography component="h1" variant="h5" className={classes.property}>Total</Typography>
            <Typography component="h1" variant="h5">{`$${getTotal()}`}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid justify={'center'} direction="row" container>
          <Typography component="h1" variant="h5" className={classes.property}>{pedido.tarjeta ? 'Tarjeta' : 'Efectivo'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant='contained' color="primary">
        Volver
      </Button>
    </DialogActions>
  </Dialog>
}

export default ModalPedido;