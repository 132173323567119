export const USER_TOKEN_KEY = 'faster_negocios_userTokenNegocios';
export const USER_KEY = 'faster_negocios_userNegocios';
export const NEGOCIO_ACTIVO_KEY = 'faster_negocios_negocioActivo';

export const getToken = () => {
    return localStorage.getItem(USER_TOKEN_KEY);
};

export const saveToken = (token) => {
    localStorage.setItem(USER_TOKEN_KEY, token);
}

export const saveNegocioActivo = (negocio) => {
    localStorage.setItem(NEGOCIO_ACTIVO_KEY, JSON.stringify(negocio));
}

export const getNegocioActivo = () => {
    return JSON.parse(localStorage.getItem(NEGOCIO_ACTIVO_KEY));
}

export const deleteToken = () => {
    localStorage.removeItem(USER_TOKEN_KEY);
    localStorage.removeItem(NEGOCIO_ACTIVO_KEY);
}

export const isAuthenticated = () => {
    return !!getToken();
}

export const getUser = async () => {
    return JSON.parse(await localStorage.getItem(USER_KEY));
};

export const getNegocioId = () => {
    return localStorage.getItem(NEGOCIO_ACTIVO_KEY);
} 