import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';
import { useHistory } from 'react-router';
import { saveNegocioActivo, saveToken } from '../../utils/authUtils';
import SelectNegocio from '../../components/login/SelectNegocio';
import { Grid, Button, CssBaseline, TextField, Link, Box, Typography, makeStyles } from '@material-ui/core';
import background from '../../assets/entrega.jpg';
import { login } from '../../api/authApi';
import Loader from '../../components/common/Loader';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titulo: {
    fontWeight: 200,
    textAlign: 'center',
    marginBottom: '30px'
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    width: '150px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '34vw', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loginForm: {
    background: 'white',
    paddingTop: '100px',
    paddingLeft: '100px',
    paddingRight: '100px',
  },
  background: {
    overflow: 'hidden',
    backgroundColor: 'black',

  },
  image: {
    opacity: 0.6
  },
  container: {
    background: 'white',
    height: '100vh'
  },
  error: {
    color: 'red'
  }
}));

const Login = () => {
  const classes = useStyles();

  const { authenticated, setAuthenticated, setNegocioActivo, setUser, mounted, user, negocioActivo } = useContext(AuthContext);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (authenticated && negocioActivo) {
      redirectHome();
    }
  }, []); // eslint-disable-line

  const onLogin = () => {
    setError(false);
    setLoading(true);
    login(email, password).then(res => {
      saveToken(res.data.token);
      setUser(res.data.user);
      toast.success("Ingreso correcto");
      if (res.data.user.negocios.length === 1) {
        setNegocioActivo(res.data.user.negocios[0]);
        saveNegocioActivo(res.data.user.negocios[0]);
        setAuthenticated(true);
        redirectHome();
      } else {
        setAuthenticated(true);
      }
    }).catch(() => {
      setError(true);
      toast.error("Error al ingresar");
    }).finally(() => setLoading(false));
  }

  const redirectHome = () => {
    history.push('/home');
  }

  const onConfirmNegocio = (negocioSelected) => {
    setNegocioActivo(negocioSelected);
    saveNegocioActivo(negocioSelected);
    toast.success("Negocio seleccionado correctamente");
    redirectHome();
  }

  const Copyright = () => {
    return (
      <>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="#">
            Faster Delivery App
          </Link>{' '}
          {new Date().getFullYear()}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Desarrollo de '}
          <Link color="inherit" href="mailto:oasoftargentina@gmail.com">
            OA Soft
          </Link>
        </Typography>
      </>
    );
  }

  const renderLoginForm = () => {
    return (
      <Grid
        onKeyPress={event => {
        if (event.key === 'Enter') onLogin();
      }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button
          type="submit"
          onClick={onLogin}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Ingresar
        </Button>
        {
          error &&
          <Typography variant={'subtitle1'} className={classes.error}>
            {'Se produjo un error al ingresar. Revisa las credenciales.'}
          </Typography>
        }
      </Grid>
    )
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={classes.background}>
        <img alt="Background" src={background} className={classes.image} />
      </Grid>
      <Grid item xs={6} className={classes.loginForm}>
        <Grid item className={classes.logoContainer}>
          <img alt="Logo Faster" className={classes.avatar} src="http://api.fasterdelivery.com.ar/images/menu/logoFaster.png" />
        </Grid>
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h3" className={classes.titulo}>
            Faster Negocios
          </Typography>
          <Grid>
            {
              (loading || !mounted) ?
                <Loader /> :
                authenticated ?
                  <SelectNegocio
                    classes={classes}
                    onConfirmNegocio={onConfirmNegocio}
                    user={user}
                  /> :
                  renderLoginForm()
            }
          </Grid>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;