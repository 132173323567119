import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const FormHeader = ({
  producto,
  onCancelar
}) => {

  return (
    <Grid justify='space-between' direction='row' container >
      <Grid item xs>
        <Typography component="h1" variant="h5">
          {
            producto ? 'Editar producto' : 'Crear producto'
          }
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={onCancelar} aria-label="delete">
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default FormHeader;