import React from 'react';
import ItemLayout from '../../common/ItemLayout';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tipoProductoItemText: {
    paddingTop: '10px',
    paddingBottom: '10px',
    textTransform: 'uppercase'
  }
}));

const TipoIngredienteItem = ({
  tipo,
  onSelect,
  selected,
}) => {

  const classes = useStyles();

  return (
    <ItemLayout selected={selected} onSelect={onSelect}>
      <Box
        component='span'
        className={classes.tipoProductoItemText}
        justifyContent={'space-between'}
        width={'90%'}
        fontSize={'12px'}
      >
        {tipo}
      </Box>
    </ItemLayout>
  )
}

export default TipoIngredienteItem;