import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Button, Paper } from '@material-ui/core';
import "./style.css";
import ModalPedido from "./ModalPedido";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto"
  },
  table: {
    width: "100%",
    minWidth: "90%"
  }
}));

const TablaPedidos = ({
  pedidos
}) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [pedidoDetails, setPedidoDetails] = useState(null);

  const details = (pedido) => {
    setPedidoDetails(pedido);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 30));
    setPage(0);
  };

  const tableHead = [
    'Id pedido',
    'Fecha',
    'Hora',
    'Tipo',
    'Tarjeta',
    'Cadete',
    'Estado',
    'Monto productos',
    'Monto Envio',
    'Monto Descuento',
    'Total',
    'Detalle',
  ]

  const classes = useStyles();
  return (
    <div className="tablaReporte">
      <Paper className={classes.root}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {tableHead.map((th, i) => <TableCell style={{fontWeight: 700}} component="th" scope="row" key={i} align="center">{th}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {pedidos.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
              .map((pedido, key) => (
                <TableRow key={key}>
                  <TableCell align="right">{pedido.id}</TableCell>
                  <TableCell align="center">{moment(pedido.created_at).format('DD/MM/YYYY').toString()}</TableCell>
                  <TableCell align="center">{moment(pedido.created_at).format('HH:mm').toString()}</TableCell>
                  <TableCell align="center">{pedido.tipo === 'Negocio' ? 'App' : 'Externo'}</TableCell>
                  <TableCell align="center">{pedido.tarjeta === true ? "Si" : "No"}</TableCell>
                  <TableCell align="center">{pedido.cadete ? pedido.cadete.nombre : '-'}</TableCell>
                  <TableCell align="center">{pedido.estado}</TableCell>
                  <TableCell align="right">{pedido.total}</TableCell>
                  <TableCell align="right">{pedido.precio_envio}</TableCell>
                  <TableCell align="right">{pedido.descuento}</TableCell>
                  <TableCell align="right">{pedido.total + pedido.precio_envio - pedido.descuento}</TableCell>
                  <TableCell align="center">
                    <Button variant='text' color='primary' onClick={() => details(pedido)}>
                      Ver
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[30]}
          component="div"
          count={pedidos.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {
          pedidoDetails &&
          <ModalPedido
            pedido={pedidoDetails}
            handleClose={() => setPedidoDetails(null)}
          />
        }
      </Paper>
    </div>
  );
};

export default TablaPedidos;
