import React from 'react';
import { Paper, makeStyles, Button, Grid } from '@material-ui/core';
import ProductoItem from '../items/ProductoItem';
import ProductosColumnHeader from './ProductosColumnHeader';
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { isMobile } from 'react-device-detect';
import Delete from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  columns: {
    minHeight: isMobile ? 'auto' : '100vh',
    marginBottom: '40px'
  },
}));

const ProductosColumn = ({
  productos,
  categoriaSelected,
  onEditarCategoria,
  productoSelected,
  setDisponible,
  onSelectProducto,
  onAgregarProducto,
  onDeleteCategory
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.columns}>
      {
        productos && categoriaSelected &&
        <>
          <ProductosColumnHeader onSubmit={onEditarCategoria} categoria={categoriaSelected} />
          {
            productos.map((producto, key) =>
              <ProductoItem
                producto={producto}
                key={'producto-' + key}
                onSelect={() => onSelectProducto(producto)}
                selected={productoSelected && (productoSelected.id === producto.id)}
                setDisponible={setDisponible}a
              />
            )
          }
          <Button
            color='primary'
            onClick={onAgregarProducto}
            startIcon={<AddRoundedIcon />}
          >
            Agregar producto
          </Button>
        </>
      }
      {
      productos?.length === 0 && categoriaSelected && 
      <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 4}}>
        <Button
        color='primary'
        onClick={() => onDeleteCategory(categoriaSelected)}
        startIcon={<Delete />}
      >
        Eliminar Categoria
      </Button>
      </Grid>
      }
    </Paper>
  )
}

export default ProductosColumn;