import React from 'react';
import { isMobile } from 'react-device-detect';
import SidebarMobile from './SidebarMobile';
import SidebarDesktop from './SidebarDesktop';

const Sidebar = () => {

  return (
    isMobile ? <SidebarMobile /> : <SidebarDesktop />
  );
}

export default Sidebar