import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex'
  }
}));

const Loader = () => {

  const classes = useStyles();

  return (
    <Grid className={classes.container} >
      <CircularProgress />
    </Grid>
  );
};

export default Loader;
