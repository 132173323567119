import { Grid, makeStyles, Paper, Typography, Backdrop, } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import NuevaCategoriaDialog from '../../components/productos/dialogs/NuevaCategoriaDialog';
import FormProducto from '../../components/productos/forms/FormProducto';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import { createProducto, eliminarProducto, updateDisponible, updateProducto } from '../../api/productosApi';
import { getCategoriasProductos } from '../../api/negociosApi.js';
import { createCategoria, getProductos, updateCategoria, sortCategorias as sortCategoriasAPI, deleteCategoria } from '../../api/categoriasProductosApi.js';
import { AuthContext } from '../../context/AuthContextProvider';
import { sortCategorias } from '../../utils/productosUtils';
import OrdenCategoriasProductosDialog from '../../components/productos/dialogs/OrdenCategoriasProductosDialog';
import ProductosColumn from '../../components/productos/columns/ProductosColumn';
import CategoriasProductosColumn from '../../components/productos/columns/CategoriasProductosColumn';
import ConfirmarDialog from '../../components/common/ConfirmarDialog.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '100px',
    marginLeft: '20px',
    marginRight: '20px'
  },
  title: {
    marginBottom: '30px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  }
}));

const Productos = () => {

  const { negocioActivo } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [categoriasProductos, setCategoriasProductos] = useState([]);
  const [categoriaSelected, setCategoriaSelected] = useState(null);
  const [productoSelected, setProductoSelected] = useState(null);
  const [crearNuevaCategoria, setCrearNuevaCategoria] = useState(false);
  const [ordenarCategorias, setOrdenarCategorias] = useState(false);
  const [crearProducto, setCrearProducto] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(null);
  const [productos, setProductos] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getCategoriasProductos(negocioActivo.id).then(response => {
      setCategoriasProductos(response.data);
      setLoading(false);
    });
  }

  const onSelectCategoria = (categoria) => {
    setLoading(true);
    if (categoria) {
      setCategoriaSelected(categoria);
      setProductoSelected(null);
      getProductos(categoria.id).then(res => {
        setProductos(res.data);
      }).finally(() => setLoading(false));
    } else {
      setProductos(null);
      setLoading(false);
    }
  }

  const refreshData = (productoId = null) => {
    setProductoSelected(null);
    getCategoriasProductos(negocioActivo.id).then(response => {
      setCategoriasProductos(response.data);
      if (categoriaSelected) {
        getProductos(categoriaSelected.id).then(res => {
          setProductos(res.data);
          if (productoId) {
            setProductoSelected(res.data.find(p => p.id === productoId));
          }
          setLoading(false);
        })
      }
    });
  }

  const onNuevaCategoria = (nombre) => {
    setLoading(true);
    createCategoria(negocioActivo.id, nombre).then(res => {
      setCategoriasProductos([
        ...categoriasProductos,
        {
          nombre,
          posicion: categoriasProductos.length,
          id: res.data.id,
          negocioId: negocioActivo.id,
          cant_productos: res.data.cant_productos
        }].sort(sortCategorias));
    }).finally(() => {
      setCrearNuevaCategoria(false);
      setLoading(false);
    })
  }

  const onEditarCategoria = (nombre, id) => {
    setLoading(true);
    updateCategoria(id, nombre).then(res => {
      setCategoriasProductos([
        ...categoriasProductos.filter(c => c.id !== id),
        {
          nombre,
          posicion: res.data.posicion,
          id: res.data.id,
          negocioId: negocioActivo.id,
          cant_productos: res.data.cant_productos
        }].sort(sortCategorias));
    }).finally(() => setLoading(false));
  }

  const setDisponible = (productoId, disponible) => {
    setLoading(true);
    updateDisponible(productoId, disponible).then(response => {
      toast.success("Producto actualizado");
      refreshData(productoId);
    }).catch((err) => {
      console.log(err);
      toast.error("Error al actualizar producto");
    });
  };

  const onGuardarProducto = (producto) => {
    let body = {
      ...producto,
      negocioId: parseInt(negocioActivo.id),
      categoriaId: categoriaSelected.id
    };
    if (!productoSelected) {
      createProducto(body).then((res) => {
        refreshData(res.data.id);
      });
    } else {
      updateProducto(productoSelected.id, body).then((res) => {
        refreshData(res.data.id);
      })
    }
  }

  const onOrdenarCategorias = (categoriasIds) => {
    setLoading(true);
    setOrdenarCategorias(false);
    sortCategoriasAPI(categoriasIds).then(() => {
      getCategoriasProductos(negocioActivo.id).then(response => {
        setCategoriasProductos(response.data);
        setLoading(false);
      })
    })
  }

  const onEliminarProducto = () => {
    setLoading(true);
    eliminarProducto(productoSelected.id).then(() => {
      setProductoSelected(null);
      onSelectCategoria(categoriaSelected);
    })
  }

  const onDeleteCategory = () => {
      setLoading(true);
      deleteCategoria(deleteCategory.id).then(() => {
        setDeleteCategory(null);
        setCategoriaSelected(null);
        fetchData();
        toast.success("Categoría eliminada exitosamente")
      })
      .catch((error) => {
        toast.error(error?.response?.data || 'Error al eliminar esta categoría');
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderFormProductoColumn = () => {
    return (
      <Paper className={classes.columns}>
        {
          (productoSelected || crearProducto) &&
          <FormProducto
            productoId={productoSelected ? productoSelected.id : null}
            onCancelar={() => {
              setProductoSelected(null);
              setCrearProducto(null);
            }}
            setLoading={setLoading}
            loading={loading}
            onGuardar={onGuardarProducto}
            negocioId={negocioActivo.id}
            onEliminar={onEliminarProducto}
          />
        }
      </Paper>
    )
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h5" className={classes.title}>
        Modificar Productos
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <CategoriasProductosColumn
            categoriaSelected={categoriaSelected}
            categoriasProductos={categoriasProductos}
            setCrearNuevaCategoria={setCrearNuevaCategoria}
            setOrdenarCategorias={setOrdenarCategorias}
            onSelectCategoria={onSelectCategoria}
          />
        </Grid>
        <Grid item xs={12} sm={4} >
          <ProductosColumn
            onSelectProducto={(producto) => {
              setProductoSelected(producto);
              setCrearProducto(false);
            }}
            categoriaSelected={categoriaSelected}
            onEditarCategoria={onEditarCategoria}
            productos={productos}
            productoSelected={productoSelected}
            setDisponible={setDisponible}
            onAgregarProducto={() => {
              setCrearProducto(true);
              setProductoSelected(null);
            }}
            onDeleteCategory={(id) => setDeleteCategory(id)}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          {
            renderFormProductoColumn()
          }
        </Grid>
      </Grid>
      {
        crearNuevaCategoria &&
        <NuevaCategoriaDialog
          handleClose={() => setCrearNuevaCategoria(false)}
          handleSubmit={onNuevaCategoria}
        />
      }
      {
        ordenarCategorias &&
        <OrdenCategoriasProductosDialog
          categorias={categoriasProductos}
          handleClose={() => setOrdenarCategorias(false)}
          onSubmit={(categoriasOrdenadas) => onOrdenarCategorias(categoriasOrdenadas)}
        />
      }
      {
        deleteCategory &&
        <ConfirmarDialog
          handleClose={() => setDeleteCategory()}
          handleConfirm={() => {
            onDeleteCategory();
          }}
          message="Estas a punto de eliminar esta categoría, esto no puede revertirse"
        />
      }
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>
    </div>
  )
}

export default Productos;