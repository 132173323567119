import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Create';
import IngredienteDialog from '../../productos/dialogs/IngredienteDialog';
import AddRoundedIcon from '@material-ui/icons/AddRounded'

const useStyles = makeStyles((theme) => ({
  list: {
    marginLeft: '10px',
    marginRight: '10px'
  }
}));

const FormIngredientesList = ({
  tipoIngrediente,
  editarIngrediente,
  agregarIngrediente,
  eliminarIngrediente
}) => {
  const classes = useStyles();

  const [ingredienteSelected, setIngredienteSelected] = useState(null);
  const [ingredientes, setIngredientes] = useState([]);
  const [showDialogIngrediente, setShowDialogIngrediente] = useState(false);

  useEffect(() => {
    setIngredientes(tipoIngrediente.ingredientes);
  }, [tipoIngrediente])


  return (
    <Grid container className={classes.list} direction='column'>
      <List>
        {
          tipoIngrediente && ingredientes.map((ingrediente, key) => {
            return (
              <React.Fragment key={key}>
                <ListItem dense >
                  <ListItemIcon className={classes.listItemIcon}>
                    <Checkbox
                      size='small'
                      edge="start"
                      checked={ingrediente.disponible}
                      tabIndex={-1}
                      color="primary"
                      onChange={() => {
                        editarIngrediente(ingrediente.nombre, ingrediente.precio, !ingrediente.disponible, ingrediente.id);
                      }}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={ingrediente.nombre + (ingrediente.precio && ingrediente.precio > 0 ? " ($" + ingrediente.precio + ")" : '')}
                  />
                  <ListItemSecondaryAction>
                    <IconButton size="small" edge="end" aria-label="edit" onClick={() => {
                      setIngredienteSelected(ingrediente);
                      setShowDialogIngrediente(true);
                    }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton size="small" edge="end" aria-label="delete" onClick={() => {
                      eliminarIngrediente(ingrediente.id);
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>

                </ListItem>
                {
                  key !== ingredientes.length - 1 &&
                  <Divider />
                }
              </React.Fragment>
            )
          }
          )
        }

      </List>
      <Button
        color='primary'
        onClick={() => {
          setIngredienteSelected(null);
          setShowDialogIngrediente(true);
        }}
        startIcon={<AddRoundedIcon />}
      >
        Agregar ingrediente global
        </Button>
      {
        showDialogIngrediente &&
        <IngredienteDialog
          ingrediente={ingredienteSelected}
          handleClose={() => setShowDialogIngrediente(false)}
          handleSubmit={(nombre, precio) => {
            if (ingredienteSelected) {
              editarIngrediente(nombre, precio, ingredienteSelected.disponible, ingredienteSelected.id);
              setShowDialogIngrediente(false);
            } else {
              agregarIngrediente(nombre, precio, true);
              setShowDialogIngrediente(false);
            }
          }}
        />
      }
    </Grid>
  )
}

export default FormIngredientesList