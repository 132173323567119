import React from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f2f2f2'
    }
  },
  selectedItem: {
    backgroundColor: '#eee',
  },
  rightBorder: {
    paddingLeft: '10px',
    marginRight: '10px',
    background: 'transparent'
  },
  selectedRightBorder: {
    background: theme.palette.primary.main
  },
}));

const ItemLayout = ({
  children,
  selected,
  onSelect
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      wrap="nowrap"
      className={`${classes.item} ${selected ? classes.selectedItem : ''}`}
      onClick={onSelect}
    >
      <Box component='span' className={`${classes.rightBorder} ${selected ? classes.selectedRightBorder : ''}`} />
      {children}
    </Grid>
  )
}

export default ItemLayout;