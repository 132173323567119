import { del, get, post, update } from "./apiService";

const endpoint = '/api/productos';

export const getProducto = (id) => get(`${endpoint}/${id}`, true);

export const updateDisponible = (id, disponible) => post(`${endpoint}/${id}/disponible`, true, {disponible});

export const createProducto = (producto) => post(endpoint, true, producto);

export const updateProducto = (id, producto) => update(`${endpoint}/${id}`, true, producto);

export const eliminarProducto = (id) => del(`${endpoint}/${id}`, true);