import React, { useEffect, useState } from "react";
import { getDetails } from "../api/authApi";
import { deleteToken, getToken, saveNegocioActivo, getNegocioActivo } from "../utils/authUtils";

export const AuthContext = React.createContext();
export const AuthContextProvider = (props) => {

  const [authenticated, setAuthenticated] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [negocioActivo, setNegocioActivo] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = getToken();
    if (token) {
      getDetails().then((res) => {
        console.log(res);
        setUser(res.data);
        setNegocioActivo(getNegocioActivo());
        setAuthenticated(true);
      }).finally(() => setMounted(true));
    } else {
      setMounted(true);
    }

    return () => {
      saveNegocioActivo(negocioActivo)
    }
  }, []); // eslint-disable-line

  const logout = () => {
    deleteToken();
    setAuthenticated(false);
    setNegocioActivo(null);
    setUser(null);
  }

  const defaultContext = {
    authenticated, setAuthenticated,
    mounted,
    negocioActivo, setNegocioActivo,
    user, setUser,
    logout
  }

  return (
    <AuthContext.Provider value={defaultContext}>
      {props.children}
    </AuthContext.Provider>
  );
};