import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';
import { getReporteNegocio } from '../../api/reportesApi';
import DesdeHastaPicker from '../../components/reportes/desdeHastaPicker/DesdeHastaPicker';
import ReportesPedidosCards from '../../components/reportes/reportesCards/ReportesPedidosCards';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '100px',
    marginLeft: '20px',
    marginRight: '20px'
  },
  title: {
    marginBottom: '30px',
  }
}));

const ReportesPedidos = () => {
  const classes = useStyles();
  const { negocioActivo } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [reportes, setReportes] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const generarReportes = () => {
    setLoading(true);
    getReporteNegocio(negocioActivo.id, startDate.format('YYYY-MM-DD').concat('T00:00:00'), endDate.format('YYYY-MM-DD').concat('T23:59:59'))
      .then((res) => {
        setReportes(res.data);
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h5" className={classes.title}>
        Reportes de pedidos
      </Typography>
      <Grid container spacing={10}>
        {
          !reportes ?
            <DesdeHastaPicker
              loading={loading}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              generarReportes={generarReportes}
            /> :
            <ReportesPedidosCards
              startDate={startDate}
              endDate={endDate}
              cleanReportes={() => setReportes(null)}
              reportes={reportes}
            />
        }
      </Grid>
    </div>
  )
}


export default ReportesPedidos;