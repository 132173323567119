import { get } from "./apiService";

const endpoint = '/api/negocios';

export const getProductos = (id) => get(`${endpoint}/${id}/productos`, true);

// export const getTiposIngredientes = (negocioId, globales = false) => get(`${endpoint}/${negocioId}/tiposingredientes${globales ? '/globales' : ''}`);
export const getTiposIngredientes = (negocioId, globales = false) => get(`${endpoint}/${negocioId}/tiposingredientes/${globales ? 'globales' : 'propios'}`);

export const getAllTiposIngredientes = (negocioId) => get(`${endpoint}/${negocioId}/tiposingredientes`);

export const getCategoriasProductos = (id) => get(`${endpoint}/${id}/categorias`, true);