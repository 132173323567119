import { del, get, post, update } from "./apiService";

const endpoint = '/api/categorias-productos';

export const getProductos = (id) => get(`${endpoint}/${id}/productos`, true);

export const createCategoria = (negocioId, nombre) => post(`${endpoint}`, true, {nombre, negocioId});

export const updateCategoria = (categoriaId, nombre) => update(`${endpoint}/${categoriaId}`, true, {nombre});

export const deleteCategoria = (categoriaId) => del(`${endpoint}/${categoriaId}`, true);

export const sortCategorias = (categorias) => post(`${endpoint}/ordenar`, true, {categorias})