import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';


const NuevaCategoriaDialog = ({
  handleClose,
  handleSubmit
}) => {

  const [nombreCategoria, setNombreCategoria] = React.useState('');

  return <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Nueva Categoria</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Por favor, ingrese el nombre de la nueva categoria.
    </DialogContentText>
      <TextField
        value={nombreCategoria}
        onChange={(e) => setNombreCategoria(e.target.value)}
        margin="dense"
        label="Nombre"
        placeholder="Ej: Empanadas"
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Cancelar
    </Button>
      <Button onClick={() => handleSubmit(nombreCategoria)} variant='contained' color="secondary">
        CREAR
    </Button>
    </DialogActions>
  </Dialog>
}

export default NuevaCategoriaDialog;