export const sortTiposIingredientesGroup = (ti1, ti2) => {
    return ti1.tipo.nombre.localeCompare(ti2.tipo.nombre);
}

export const sortIngredientes = (i1, i2) => {
    return i1.nombre.localeCompare(i2.nombre);
}

export const getTipoIngredienteSubtitle = (tipo) => {
    let desc = '';
    if (tipo.minimo > 0) {
      desc += 'Minimo ' + tipo.minimo;
      if (tipo.maximo > 0) {
        desc += ', ';
      }
    }
    if (tipo.maximo > 0) {
      desc += (tipo.minimo > 0 ? 'maximo ' : 'Maximo ') + tipo.maximo
    }
    return desc;
  }

export const tiposIngredientesMock = [
  {
      "id": 1,
      "nombre": "Acompañamientos",
      "titulo": "Seleccionar el acompañamiento",
      "prefijo": null,
      "minimo": 1,
      "maximo": 1,
      "negocio_id": null
  },
  {
      "id": 2,
      "nombre": "Aderezos",
      "titulo": "Seleccionar los aderezos",
      "prefijo": null,
      "minimo": 0,
      "maximo": 0,
      "negocio_id": null
  },
  {
      "id": 3,
      "nombre": "Extras",
      "titulo": "Querés agregar algún extra ?",
      "prefijo": "Extra",
      "minimo": 0,
      "maximo": 0,
      "negocio_id": null
  },
  {
      "id": 4,
      "nombre": "1 Gusto",
      "titulo": "Seleccione un gusto",
      "prefijo": null,
      "minimo": 1,
      "maximo": 1,
      "negocio_id": null
  },
  {
      "id": 5,
      "nombre": "2 Gustos",
      "titulo": "Seleccione los gustos",
      "prefijo": null,
      "minimo": 1,
      "maximo": 2,
      "negocio_id": null
  },
  {
      "id": 6,
      "nombre": "3 Gustos",
      "titulo": "Seleccione los gustos",
      "prefijo": null,
      "minimo": 1,
      "maximo": 3,
      "negocio_id": null
  },
  {
      "id": 7,
      "nombre": "4 Gustos",
      "titulo": "Seleccione los gustos",
      "prefijo": null,
      "minimo": 1,
      "maximo": 4,
      "negocio_id": null
  },
  {
      "id": 8,
      "nombre": "Opción",
      "titulo": "Seleccionar opción",
      "prefijo": null,
      "minimo": 1,
      "maximo": 1,
      "negocio_id": null
  },
  {
      "id": 9,
      "nombre": "Sin (Ingredientes quitables)",
      "titulo": "Querés sacar algo?",
      "prefijo": "Sin",
      "minimo": 0,
      "maximo": 0,
      "negocio_id": null
  },
  {
      "id": 10,
      "nombre": "Sugeridos",
      "titulo": "Llevate también...",
      "prefijo": null,
      "minimo": 0,
      "maximo": 0,
      "negocio_id": null
  },
  {
      "id": 11,
      "nombre": "Bebidas",
      "titulo": "Elegí tu bebida",
      "prefijo": null,
      "minimo": 1,
      "maximo": 1,
      "negocio_id": null
  },
  {
      "id": 13,
      "nombre": "TipoIngredientePrueba",
      "titulo": "Seleccione TipoIngredientePrueba...",
      "prefijo": null,
      "minimo": 1,
      "maximo": 1,
      "negocio_id": 2
  }
];