import React, { useEffect } from 'react'
import { FormControl, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';

const SelectNegocio = ({ classes, onConfirmNegocio, user, sidebar, negocioActivo }) => {
  const [negociosList] = React.useState(user.negocios);
  const [negocioSelected, setNegocioSelected] = React.useState(user.negocios[0]);
  const [showDropdown, setShowDropdown] = React.useState(false);

  useEffect(() => {
    if(negocioActivo) {
      setNegocioSelected(user.negocios.find(n => n.id === negocioActivo.id));
    }
  }, [negocioActivo]);  // eslint-disable-line

  return (
    <>
      {(!sidebar || showDropdown) &&
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Negocio</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={negocioSelected}
            onChange={(event) => {
              setNegocioSelected(event.target.value);
              sidebar && setShowDropdown(false);
              sidebar && onConfirmNegocio(event.target.value) 
            }}
          >
            {
              negociosList.map((negocio, i) => <MenuItem key={i} value={negocio}>{negocio.nombre}</MenuItem>)
            }
          </Select>
        </FormControl>
      }
      {
        sidebar ?
          <Button
            type="submit"
            onClick={() => setShowDropdown(true)}
            fullWidth
            variant="text"
            color="primary"
            startIcon={<BusinessIcon />}
            className={classes.submit}
          >
            Cambiar negocio
            </Button>
          : (<Button
            type="submit"
            onClick={() => onConfirmNegocio(negocioSelected)}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Seleccionar
          </Button>)
      }
    </>
  )
}

export default SelectNegocio;