import { del, post, update } from './apiService';

const endpoint = '/api/tiposingredientes';

export const createTipoIngrediente = (tipoIngrediente) => post(endpoint, true, tipoIngrediente);

export const updateTipoIngrediente = (tipoIngrediente, id) =>
  update(`${endpoint}/${id}`, true, tipoIngrediente);

export const deleteTipoIngrediente = (tipoIngredienteId) =>
  del(`${endpoint}/${tipoIngredienteId}`, true);
