//Configuración global: ver en material-ui.com/customization/default-theme/
//https://material-ui.com/customization/color/#official-color-tool
import { createMuiTheme } from "@material-ui/core/styles";
// import unisans from "./utils/fonts/UniSans";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff4364",
    },
    secondary: {
      main: "#717171",
    },
    success: {
      main: "#7782c4",
    },
    dark: {
      main: '#000000',
      secondary: '#1e1e1e'
    },
    light: {
      main: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        marginBottom: 10,
        borderRadius: 36,
      },
      root: {
        textTransform: 'none',

      },
    },
    MuiFilledInput: {
      formControl: {
        backgroundColor: 'none'
      },
      root: {
        backgroundColor: 'none'
      }
    },
    MuiCssBaseline: {
      "@global": {
        // "@font-face": 'Spartan',
      },
    },
  },

  typography: {
    useNextVariants: true,
    supressDeprecationWarnings: true,
    fontFamily: ["Roboto", "sans-serif"].join(","),
    textTransform: "inherit",
    fontSize: 14,
  },
});

export default theme;
