import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import { weekDays } from '../../utils/dateUtils';
import moment from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';

const NewHorarioDialog = ({
  handleClose,
  handleSubmit,
  dia
}) => {

  const [abre, setAbre] = useState(moment().set({ hour: 10, minute: 0, second: 0 }).toDate());
  const [cierra, setCierra] = useState(moment().set({ hour: 20, minute: 0, second: 0 }).toDate());

  return <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{`Nuevo horario para ${weekDays[dia].name.toLowerCase()}`}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Por favor, seleccione el horario de apertura y cierre. Recuerde que puede agregar mas de un horario por día. Por ejemplo: desde 14hs a 18hs y desde 20hs a 23hs.
      </DialogContentText>
      <Grid container spacing={2} direction='row'>
        <Grid item xs={6}>
          <KeyboardTimePicker autoOk={true} ampm={false} variant="inline" label="Abre" value={abre} onChange={setAbre} />
        </Grid>
        <Grid item xs={6}>
          <KeyboardTimePicker ampm={false} autoOk={true} variant="inline" label="Cierra" value={cierra} onChange={setCierra} />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Cancelar
    </Button>
      <Button onClick={() => {
        handleSubmit(dia, moment(abre).format('HH:mm:ss'), moment(cierra).format('HH:mm:ss'));
        handleClose();
      }} variant='contained' color="secondary">
        Agregar horario
      </Button>
    </DialogActions>
  </Dialog>
}

export default NewHorarioDialog;