import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: '10px',
    marginLeft: '10px',
    verticalAlign: 'middle'
  }
}));

const StatusDot = ({
  color
}) => {
  
  const classes = useStyles();

  return <span className={classes.container}>
    <FiberManualRecordIcon style={{ color }} />
  </span>
}

export default StatusDot