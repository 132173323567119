import moment from 'moment';

export const weekDays = [
  { number: 0, name: 'Domingo' },
  { number: 1, name: 'Lunes' },
  { number: 2, name: 'Martes' },
  { number: 3, name: 'Miércoles' },
  { number: 4, name: 'Jueves' },
  { number: 5, name: 'Viernes' },
  { number: 6, name: 'Sábado' }
];

export const checkHorario = (abre, cierra, horarios) => {
  let newAbre = moment(abre, 'HH:mm');
  let newCierra = moment(cierra, 'HH:mm');
  if (newCierra.isBefore(newAbre)) {
    return {
      result: false,
      error: 'Las hora de cierre es anterior a la hora de apertura'
    }
  }
  let horarioPisado = horarios.find(h =>
    (newAbre.isAfter(moment(h.abre, 'HH:mm')) && newAbre.isBefore(moment(h.cierra, 'HH: mm'))) ||
    (newCierra.isAfter(moment(h.abre, 'HH:mm')) && newCierra.isBefore(moment(h.cierra, 'HH: mm')))
  );

  if (horarioPisado) {
    return {
      result: false,
      error: `El nuevo horario se pisa con el siguiente horario: abre ${horarioPisado.abre} hs y cierra ${horarioPisado.cierra}`
    }
  } else {
    return {
      result: true,
      error: null
    }
  }
}