import React from 'react';
import { Grid, Typography, makeStyles, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: '800'
  },
  green: {
    color: 'green'
  },
  end: {
    textAlign: 'end'
  }
}));


const ReporteMontoPedidosCard = ({
  resumen
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" spacing={5} className={classes.marginCards}>
      <Grid item direction="column" xs={12} md={3} style={{ textAlign: 'center' }}>
        <Grid item>
          <Typography variant="h6" >Total vendido</Typography>
        </Grid>
        <Grid item >
          <Typography variant="h5" className={classes.bold + ' ' + classes.green}>{`$${Intl.NumberFormat('es-ES').format(resumen.totalVendido)}`}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="button" className={classes.bold}> Vendido por la app</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{`$${resumen.totalApp}`}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="subtitle2" className={classes.bold}>En efectivo</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{`$${resumen.totalAppEfectivo}`}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="subtitle2" className={classes.bold}>Con tarjeta</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{`$${resumen.totalAppTarjeta}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="button" className={classes.bold}> Vendido externo</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{`$${resumen.totalExternos}`}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="subtitle2" className={classes.bold}>En efectivo</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{`$${resumen.totalExternosEfectivo}`}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <Typography variant="subtitle2" className={classes.bold}>Con tarjeta</Typography>
          </Grid>
          <Grid item xs={3} className={classes.end}>
            <Typography variant="subtitle2" className={classes.bold + ' ' + classes.green} >{`$${resumen.totalExternosTarjeta}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReporteMontoPedidosCard;