import {
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FormHeader from './FormHeader';
import FormIngredientesList from './FormIngredientesList';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import { sortIngredientes } from '../../../utils/ingredientesUtils';
import { isMobile } from 'react-device-detect';

const tipoFormMock = {
  nombre: '',
  titulo: '',
  prefijo: null,
  minimo: 0,
  maximo: 0,
  id: null,
  repetible: false,
  ingredientes: [],
  global: true,
  ingredientesActualizados: true,
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: '100%',
  },
  gridContainer: {
    padding: '10px',
  },
  submitButton: {
    marginTop: '20px',
  },
}));

const FormTipoIngrediente = ({ tipoIngrediente, negocioId, onSubmit, onCancelar, onEliminar }) => {
  const classes = useStyles();

  const [tipoForm, setTipoForm] = useState(tipoFormMock);

  useEffect(() => {
    if (tipoIngrediente) {
      setTipoForm({
        ...tipoIngrediente,
        negocioId,
        ingredientes: tipoIngrediente.ingredientes.sort(sortIngredientes),
        ingredientesActualizados: false,
      });
    } else {
      setTipoForm({
        ...tipoFormMock,
        negocioId,
      });
    }
  }, [tipoIngrediente]);

  const agregarIngrediente = (nombre, precio, disponible) => {
    setTipoForm({
      ...tipoForm,
      ingredientesActualizados: true,
      ingredientes: [
        ...tipoForm.ingredientes,
        {
          nombre,
          precio,
          disponible,
          id: parseInt(moment().unix() + '' + moment().get('ms')),
          tipo_ingrediente_id: tipoForm.id,
        },
      ].sort(sortIngredientes),
    });
  };

  const eliminarIngrediente = (ingrediente_id) => {
    setTipoForm({
      ...tipoForm,
      ingredientesActualizados: true,
      ingredientes: tipoForm.ingredientes
        .filter((ing) => ing.id !== ingrediente_id)
        .sort(sortIngredientes),
    });
  };

  const editarIngrediente = (nombre, precio, disponible, ingrediente_id) => {
    setTipoForm({
      ...tipoForm,
      ingredientesActualizados: true,
      ingredientes: [
        ...tipoForm.ingredientes.filter((ing) => ing.id !== ingrediente_id),
        {
          nombre,
          precio,
          disponible,
          id: ingrediente_id,
          tipo_ingrediente_id: tipoForm.id,
        },
      ].sort(sortIngredientes),
    });
  };

  return (
    <Grid container direction="row" className={classes.gridContainer}>
      <FormHeader tipoIngrediente={tipoIngrediente} onCancelar={onCancelar} />
      {tipoForm && (
        <>
          <Grid item xs={12} sm={5}>
            <Grid item xs={12}>
              <TextField
                value={tipoForm.nombre}
                onChange={(e) => setTipoForm({ ...tipoForm, nombre: e.target.value })}
                margin="dense"
                label="Nombre"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={tipoForm.repetible}
                    onChange={(e) => setTipoForm({ ...tipoForm, repetible: e.target.checked })}
                  />
                }
                label={
                  <Grid container direction="row">
                    <Grid item>
                      <HtmlTooltip
                        title={
                          <>
                            El ingrediente tendrá un <b>minimo</b> y un <b>máximo</b>. Si la opción{' '}
                            <b>ingrediente repetible</b> está marcada, el cliente podrá elegir{' '}
                            <b>mas de un</b> mismo ingrediente. Por ejemplo aderezos, el cliente
                            puede elegir 2 de mayonesa y uno de ketchup, o 3 de ketchup, o 1 de cada
                            uno, hasta el maximo definido.{' '}
                          </>
                        }
                      >
                        <InfoIcon />
                      </HtmlTooltip>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.caption} variant="subtitle2">
                        Ingrediente repetible?
                      </Typography>
                    </Grid>
                  </Grid>
                }
                labelPlacement="start"
              />
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={tipoForm.minimo}
                  onChange={(e) => setTipoForm({ ...tipoForm, minimo: e.target.value })}
                  margin="dense"
                  label="Minimo"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={tipoForm.maximo}
                  onChange={(e) => setTipoForm({ ...tipoForm, maximo: e.target.value })}
                  margin="dense"
                  label="Maximo"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={tipoForm.titulo}
                onChange={(e) => setTipoForm({ ...tipoForm, titulo: e.target.value })}
                margin="dense"
                label="Titulo en app"
                fullWidth
              />
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  className={classes.submitButton}
                  onClick={() => onSubmit(tipoForm)}
                  variant="contained"
                  color="primary"
                >
                  Guardar
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className={classes.submitButton}
                  onClick={() => onEliminar(tipoIngrediente)}
                  variant="contained"
                  color="secondary"
                >
                  Eliminar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {(!tipoIngrediente || tipoIngrediente.global) && isMobile && (
            <Grid item xs={12} sm={7}>
              <Typography component="h1" variant="h5">
                {tipoIngrediente
                  ? 'Modificar ingredientes del tipo'
                  : 'Agregar ingredientes al tipo'}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            {tipoForm.global && (
              <FormIngredientesList
                tipoIngrediente={tipoForm}
                agregarIngrediente={agregarIngrediente}
                eliminarIngrediente={eliminarIngrediente}
                editarIngrediente={editarIngrediente}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FormTipoIngrediente;
