import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, makeStyles, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  select: {
    minWidth: '100%',
    marginBottom: '30px'
  }
}));

const NuevoTipoIngredienteDialog = ({
  handleClose,
  handleSubmit,
  tiposNegocio,
  ingredientesActuales,
  globales
}) => {
  const classes = useStyles();

  const [tipoSelected, setTipoSelected] = useState(null);
  const [nuevoTipo, setNuevoTipo] = useState(false);
  const [tiposDisponibles, setTiposDisponibles] = useState([]);

  useEffect(() => {
    let tipos = [...tiposNegocio];
    ingredientesActuales.map((ti) => {
      tipos = tipos.filter(t => t.id !== ti.tipo.id);
      return -1;
    });
    setTiposDisponibles(tipos);
  }, [tiposNegocio]);

  useEffect(() => {
    if (nuevoTipo) {
      setTipoSelected({
        nombre: '',
        titulo: '',
        prefijo: null,
        minimo: 0,
        maximo: 0,
        id: null,
        repetible: false
      });
    } else {
      setTipoSelected(null);
    }
  }, [nuevoTipo]); // eslint-disable-line

  return <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Agregar tipo de ingrediente</DialogTitle>
    <DialogContent>
      <DialogContentText>
        ¿Qué tipo de ingrediente es?
      </DialogContentText>
      <Select
        className={classes.select}
        label='Tipo de ingrediente'
        value={(tipoSelected && tipoSelected.id) ? tipoSelected : null}
        disabled={nuevoTipo}
        onChange={(e) => setTipoSelected(e.target.value)}
      >
        <MenuItem value={null}></MenuItem>
        {
          tiposDisponibles.map((tipo, key) => {
            return <MenuItem key={key} value={tipo}>{tipo.nombre}</MenuItem>
          })
        }
      </Select>
      {
        !globales && <>
          <DialogContentText>
            Si no encuentra su tipo, puede crearlo completando los siguientes datos
          </DialogContentText>
          <Switch checked={nuevoTipo} onChange={(e) => setNuevoTipo(e.target.checked)} color="primary" />
        </>
      }
      {
        (nuevoTipo || tipoSelected) &&
        <Grid container>
          <Grid item xs={6}>
            <TextField
              value={tipoSelected ? tipoSelected.nombre : ''}
              onChange={(e) => setTipoSelected({ ...tipoSelected, nombre: e.target.value })}
              margin="dense"
              disabled={!nuevoTipo}
              label="Nombre de tipo de ingrediente"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            {
              (tipoSelected && tipoSelected.prefijo) ?
                <TextField
                  value={tipoSelected.prefijo}
                  onChange={(e) => setTipoSelected({ ...tipoSelected, prefijo: e.target.value })}
                  margin="dense"
                  disabled={true}
                  label="Prefijo de nombre de ingrediente"
                  fullWidth
                /> :
                <FormControlLabel
                  disabled={!nuevoTipo}
                  control={<Switch color="primary" disabled={!nuevoTipo} checked={tipoSelected ? tipoSelected.repetible : false} onChange={(e) => setTipoSelected({ ...tipoSelected, repetible: e.target.checked })} />}
                  label={<Typography className={classes.caption} variant='subtitle2'>Ingrediente repetible?</Typography>}
                  labelPlacement="start"
                />
            }
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={tipoSelected ? tipoSelected.minimo : ''}
              onChange={(e) => setTipoSelected({ ...tipoSelected, minimo: e.target.value })}
              margin="dense"
              disabled={!nuevoTipo}
              label="Minimo del tipo de ingredientes"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={tipoSelected ? tipoSelected.maximo : ''}
              onChange={(e) => setTipoSelected({ ...tipoSelected, maximo: e.target.value })}
              margin="dense"
              disabled={!nuevoTipo}
              label="Maximo del tipo de ingrediente"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={tipoSelected ? tipoSelected.titulo : ''}
              onChange={(e) => setTipoSelected({ ...tipoSelected, titulo: e.target.value })}
              margin="dense"
              disabled={!nuevoTipo}
              label="Titulo en app"
              fullWidth
            />
          </Grid>
        </Grid>
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancelar
    </Button>
      <Button onClick={() => {
        handleSubmit(tipoSelected)
        handleClose();
      }} variant='contained' color="primary">
        Agregar
    </Button>
    </DialogActions>
  </Dialog>
}

export default NuevoTipoIngredienteDialog;