import React from 'react';
import ItemLayout from '../../common/ItemLayout';
import { Box, Hidden, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tipoProductoItemText: {
    paddingTop: '10px',
    paddingBottom: '10px',
    textTransform: 'uppercase'
  },
  chip: {
    float: 'right',
    background: 'black',
    color: 'white',
    borderRadius: '50%',
    paddingTop: '2px',
    paddingBottom: '2px',
    textAlign: 'center',
    width: '24px'
  },
}));

const CategoriaProductoItem = ({
  tipo,
  onSelect,
  selected,
  cant
}) => {

  const classes = useStyles();

  return (
    <ItemLayout selected={selected} onSelect={onSelect}>
      <Box
        component='span'
        className={classes.tipoProductoItemText}
        justifyContent={'space-between'}
        width={'90%'}
        fontSize={'12px'}
      >
        {tipo}
        <Hidden mdDown>
          <span className={classes.chip} >
            {'' + cant}
          </span>
        </Hidden>
      </Box>
    </ItemLayout>
  )
}

export default CategoriaProductoItem;