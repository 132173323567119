import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, CssBaseline, Typography, Divider, IconButton, Grid, Button, Avatar } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SidebarItems from './SidebarItems';
import { FASTERAPIURL } from '../../api/apiService';
import { AuthContext } from '../../context/AuthContextProvider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SelectNegocio from '../../components/login/SelectNegocio';
import { saveNegocioActivo } from '../../utils/authUtils';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.dark.main,
    color: theme.palette.primary.main,
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    color: theme.palette.primary.main
  },
  title: {
    flexGrow: 1,
    fontSize: 18,
  },
}));

const Mobile = () => {
  const classes = useStyles();
  const { negocioActivo, logout, user, setNegocioActivo } = useContext(AuthContext);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changeNegocio = (negocio) => {
    setNegocioActivo(negocio);
    saveNegocioActivo(negocio);
  }

  return (
    <>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {
            open ?
              <Grid direction='row'>
                <Typography component='span' className={classes.title}>
                  Faster Negocios
                </Typography>
                <IconButton className={classes.toolbarIcon} onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </Grid> :
              <IconButton className={classes.toolbarIcon} onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton>
          }
        </div>
        <Divider />
        {
          open &&
            <Grid className={classes.fit} container direction="column" alignItems='center'>
              <Typography component='span' className={classes.title} variant="h5" color="primary">
                {negocioActivo.nombre}
              </Typography>
              <Avatar className={classes.avatar} src={FASTERAPIURL + negocioActivo.imagen} />
              <Button className={classes.logout} onClick={logout} color="primary" startIcon={<ExitToAppIcon />}>SALIR</Button>
              {user.negocios.length > 1 && <SelectNegocio classes={classes} user={user} onConfirmNegocio={changeNegocio} sidebar />}
            </Grid>
        }
        <SidebarItems />
      </Drawer>
    </>
  );
}

export default Mobile;
